import React from 'react';

import {Toggle} from '@sphericsio/forms';
import {DetailText} from '@sphericsio/design-system';

type ToggleWithLabelProps = {
    title: string;
    name: string;
};

export function ToggleWithLabel({title, name}: ToggleWithLabelProps) {
    return (
        <div className="flex items-center">
            <div className="w-8/12">
                <DetailText>{title}</DetailText>
            </div>
            <div className="w-4/12 text-right">
                <Toggle name={name} onLabel="Yes" offLabel="No" testId={`${name}-ft`} />
            </div>
        </div>
    );
}
