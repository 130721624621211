import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {parseJSON} from 'date-fns';

import {DetailText, VStack, Link, Button, HStack, Modal} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {Loading} from '../loading';
import {ErrorCard} from '../error-card';
import {CustomDatePicker} from '../date-picker';

const GetCompanySubscriptionDocument = graphql(/* GraphQL */ `
    query GetCompanySubscription($id: ID!) {
        getCompany(id: $id) {
            id
            subscription {
                stripeSubscriptionId
                isTrial
                trialPeriodEnds
                status
            }
        }
    }
`);

const ExtendSubscriptionTrialDocument = graphql(/* GraphQL */ `
    mutation ExtendSubscriptionTrial($companyAccountId: ID!, $trialEnd: DateTime!) {
        extendSubscriptionTrial(companyAccountId: $companyAccountId, trialEnd: $trialEnd) {
            stripeSubscriptionId
            isTrial
            trialPeriodEnds
        }
    }
`);

const CancelCompanySubscriptionDocument = graphql(/* GraphQL */ `
    mutation CancelCompanySubscription($companyAccountId: ID!) {
        cancelCompanySubscription(companyAccountId: $companyAccountId) {
            id
        }
    }
`);

type CompanySubscriptionDataProps = {
    companyAccountId: string;
};
export function CompanySubscriptionData({companyAccountId}: CompanySubscriptionDataProps) {
    const [showModal, setShowModal] = React.useState(false);
    const [extendTrialDate, setExtendTrialDate] = React.useState<Date>();

    const {loading, error, data} = useQuery(GetCompanySubscriptionDocument, {
        variables: {id: companyAccountId},
    });
    const [extendTrial, {loading: submitting, error: errorSubmitting}] = useMutation(
        ExtendSubscriptionTrialDocument,
    );

    const [
        cancelCompanySubscription,
        {loading: cancelling, error: errorCancelling, data: cancelledSubscription},
    ] = useMutation(CancelCompanySubscriptionDocument, {
        refetchQueries: [
            {query: GetCompanySubscriptionDocument, variables: {id: companyAccountId}},
        ],
    });

    const trialPeriodEnds = data?.getCompany.subscription?.trialPeriodEnds
        ? parseJSON(data?.getCompany.subscription?.trialPeriodEnds)
        : new Date();

    const cancelSubscriptionDisabled =
        data?.getCompany &&
        (data.getCompany.subscription?.status === 'cancelled' ||
            data.getCompany.subscription?.status === 'expired');

    React.useEffect(() => {
        if (cancelledSubscription?.cancelCompanySubscription.id) {
            setShowModal(false);
        }
    }, [cancelledSubscription]);

    return (
        <>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <VStack>
                    <DetailText>
                        Are you sure you want to cancel the subscription for this company?
                    </DetailText>
                    {errorCancelling && (
                        <DetailText colour="error">
                            Something went wrong with cancelling the subscription. Please report
                            this error.
                        </DetailText>
                    )}
                    <div className="flex flex-row justify-end">
                        <Button
                            isLoading={cancelling}
                            onPress={() =>
                                cancelCompanySubscription({
                                    variables: {
                                        companyAccountId,
                                    },
                                })
                            }
                        >
                            Yes, cancel subscription
                        </Button>
                    </div>
                </VStack>
            </Modal>
            <VStack>
                {loading && <Loading />}
                {error && <ErrorCard />}
                {data && data.getCompany.subscription == null && (
                    <DetailText>This company does not have a subscription.</DetailText>
                )}
                {data && data.getCompany.subscription != null && (
                    <>
                        <div>
                            <Link
                                active
                                href={`https://dashboard.stripe.com/subscriptions/${data.getCompany.subscription.stripeSubscriptionId}`}
                                openInNewWindow
                            >
                                View subscription in Stripe dashboard
                            </Link>
                        </div>
                        {data.getCompany.subscription.isTrial && (
                            <>
                                <HStack>
                                    <CustomDatePicker
                                        value={extendTrialDate ?? trialPeriodEnds}
                                        minDate={trialPeriodEnds}
                                        onChange={setExtendTrialDate}
                                    />
                                    <Button
                                        isDisabled={extendTrialDate == null}
                                        isLoading={submitting}
                                        onPress={() => {
                                            if (extendTrialDate != null) {
                                                extendTrial({
                                                    variables: {
                                                        companyAccountId,
                                                        trialEnd: extendTrialDate.toJSON(),
                                                    },
                                                });
                                            }
                                        }}
                                    >
                                        Extend trial
                                    </Button>
                                </HStack>
                                {errorSubmitting && <ErrorCard />}
                            </>
                        )}
                        <Button
                            isDisabled={cancelSubscriptionDisabled}
                            onPress={() => setShowModal(true)}
                        >
                            Cancel subscription
                        </Button>
                    </>
                )}
            </VStack>
        </>
    );
}
