import React from 'react';
import BugsnagClient from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import config from '../../config';
import {useCurrentUser} from './user-context';

export const Bugsnag = BugsnagClient.start({
    apiKey: config.bugsnag.apiKey,
    plugins: [new BugsnagPluginReact()],
    releaseStage: DEPLOY_ENV,
    appVersion: BUILD_NUMBER,
    enabledReleaseStages: config.bugsnag.enabled ? [DEPLOY_ENV] : [],
});

const BugsnagPlugin = Bugsnag.getPlugin('react');
if (BugsnagPlugin == null) {
    const err = new Error('missing React plugin for bugsnag');
    Bugsnag.notify(err);
    throw err;
}

export const ErrorBoundary = BugsnagPlugin.createErrorBoundary(React);

export const BugsnagContext = React.createContext(Bugsnag);
const BugsnagProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
    const user = useCurrentUser();
    React.useEffect(() => {
        Bugsnag.setUser(user?.id, user?.email, user?.name);
    }, [Bugsnag, user]);

    return <BugsnagContext.Provider value={Bugsnag}>{children}</BugsnagContext.Provider>;
};

export {BugsnagProvider};
