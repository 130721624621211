import {useLazyQuery} from '@apollo/client';
import React from 'react';
import * as Yup from 'yup';

import {Button, DetailText, Heading2, Panel, VStack} from '@sphericsio/design-system';
import {Form, Input} from '@sphericsio/forms';
import {getErrorMessage} from '@sphericsio/mvp-ui-common';

import {graphql} from '../../graphql/generated';
import {ErrorCard} from '../error-card';

export const GetAccountDocument = graphql(/* GraphQL */ `
    query GetAccount($companyAccountId: String!, $financialAccountId: String!) {
        getAccount(companyAccountId: $companyAccountId, financialAccountId: $financialAccountId) {
            id
            name
            description
        }
    }
`);

const ERROR_CODES: Record<string, string> = {
    MISSING_PROPERTIES: 'Account is missing required properties',
};

type GetAccountForm = {
    accountId: string;
};

const schema = Yup.object({
    accountId: Yup.string().required('Please enter the account id'),
});

const ViewAccount = ({companyAccountId}: DebuggingProps) => {
    const [getAccount, {loading, error, data}] = useLazyQuery(GetAccountDocument);

    const onSubmit = (values: GetAccountForm) => {
        if (values.accountId) {
            getAccount({
                variables: {companyAccountId, financialAccountId: values.accountId},
            });
        }
    };

    return (
        <div className="w-60">
            <Panel>
                <Form<GetAccountForm>
                    initialValues={{
                        accountId: '',
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                >
                    <Input name="accountId" placeholder="External account id" />
                    {error && (
                        <ErrorCard
                            message={getErrorMessage(error, {extraErrorCodes: ERROR_CODES})}
                        />
                    )}

                    <Button isLoading={loading} type="submit">
                        Fetch account
                    </Button>
                </Form>
                {data && data.getAccount && (
                    <div className="flex flex-col space-y-2 pt-2">
                        <DetailText bold>Account information</DetailText>
                        <DetailText>For more information, please see logs.</DetailText>
                        <div className="grid grid-rows-2 gap-1 auto-rows-max items-center w-3/4">
                            <DetailText bold className="row-start-1">
                                Id:
                            </DetailText>
                            <DetailText bold className="row-start-2 row-span-3">
                                Name:
                            </DetailText>
                            <DetailText bold className="row-start-5">
                                Description:
                            </DetailText>
                            <DetailText className="row-start-1">{data.getAccount.id}</DetailText>
                            <DetailText className="row-start-2 row-span-3">
                                {data.getAccount.name}
                            </DetailText>
                            <DetailText className="row-start-5">
                                {data.getAccount.description}
                            </DetailText>
                        </div>
                    </div>
                )}
            </Panel>
        </div>
    );
};

type DebuggingProps = {companyAccountId: string};

export const Debugging = ({companyAccountId}: DebuggingProps) => {
    return (
        <VStack>
            <Heading2>Debugging</Heading2>
            <ViewAccount companyAccountId={companyAccountId} />
        </VStack>
    );
};
