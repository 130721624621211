import React from 'react';
import {useMutation} from '@apollo/client';

import {Button, VStack, Heading3, DetailText} from '@sphericsio/design-system';
import {getErrorMessage} from '@sphericsio/mvp-ui-common';

import {graphql} from '../../graphql/generated';
import {ErrorCard} from '../error-card';

const DisableUser = graphql(/* GraphQL */ `
    mutation DisableUser($user: ID!) {
        disableUser(userAccountId: $user) {
            id
        }
    }
`);

type User = {
    id: string;
    name: string;
    email: string;
};

export function DisableUserForm({user, onDisabled}: {user: User; onDisabled: () => unknown}) {
    const [disableUser, {loading, error}] = useMutation(DisableUser);
    async function onConfirmDisable() {
        await disableUser({variables: {user: user.id}});
        onDisabled();
    }
    return (
        <VStack align="center">
            <Heading3>Are you sure?</Heading3>
            <DetailText>
                Are you sure you want to disable the user <DetailText bold>{user.name}</DetailText>{' '}
                ({user.email})?
            </DetailText>
            <DetailText bold colour="error">
                This cannot be reversed through the admin panel
            </DetailText>

            {error && <ErrorCard message={getErrorMessage(error, {})} />}
            <Button bg="error" onPress={onConfirmDisable} isLoading={loading}>
                Yes, disable {user.name}
            </Button>
        </VStack>
    );
}
