import React from 'react';
import {useMutation} from '@apollo/client';

import {Button, DetailText, Heading2, HStack, VStack} from '@sphericsio/design-system';

import {graphql} from '../../../graphql/generated';
import {AlertIcon} from '../../icons';
import {GetReductionTargetDocument} from './company-targeting-data';

const resetBaselineYear = graphql(/* GraphQL */ `
    mutation ResetTargetingBaseline($baselineId: ID!) {
        resetTargetingBaseline(baselineId: $baselineId)
    }
`);
type BaselineYear = {
    id: string;
    company_reporting_period: {
        period_start: string;
        period_end: string;
    };
};
type BaselineYearResetProps = {
    baseline?: BaselineYear | null;
    companyAccountId: string;
};
export const BaselineYearReset = ({baseline, companyAccountId}: BaselineYearResetProps) => {
    const [reset, {loading}] = useMutation(resetBaselineYear, {
        refetchQueries: [
            {
                query: GetReductionTargetDocument,
                variables: {id: companyAccountId},
            },
        ],
    });
    const onResetPress = React.useCallback(() => {
        if (!baseline) return;
        const ok = confirm('Do you really want to reset the baseline year for this company?');
        if (ok) {
            reset({variables: {baselineId: baseline.id}});
        }
    }, [reset, companyAccountId]);
    return (
        <VStack>
            <Heading2>Reset baseline year</Heading2>
            {!baseline && <DetailText>No baseline set</DetailText>}
            {baseline?.company_reporting_period && (
                <>
                    <HStack>
                        <DetailText inline={false}>Targeting baseline period:</DetailText>
                        <DetailText
                            bold
                        >{`${baseline.company_reporting_period.period_start} to ${baseline.company_reporting_period.period_end}`}</DetailText>
                    </HStack>
                    <HStack>
                        <DetailText bold>
                            Warning! This will delete the baseline year and all related operational
                            data associated with this year
                        </DetailText>
                    </HStack>
                    <HStack>
                        <Button
                            icon={<AlertIcon />}
                            isLoading={loading}
                            bg="error"
                            onPress={onResetPress}
                        >
                            Reset baseline year
                        </Button>
                    </HStack>
                </>
            )}
        </VStack>
    );
};
