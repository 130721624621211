import React from 'react';
import {useParams} from 'react-router-dom';
import {useQuery} from '@apollo/client';

import {DetailText, Heading1, VStack, TabBar, TabItemConfig} from '@sphericsio/design-system';

import {View} from '../components/view';
import {Loading} from '../components/loading';
import {ErrorCard} from '../components/error-card';
import {graphql} from '../graphql/generated';
import {CompanyAccountingData} from '../components/company/accounting-data';
import {CompanySubscriptionData} from '../components/company/subscription';
import {UserManagement} from '../components/company/user-management';
import {CompanyTargetingData} from '../components/company/targeting-data/company-targeting-data';
import {FeatureFlags} from '../components/company/feature-flags';
import {Debugging} from '../components/company/debugging';
import {Settings} from '../components/company/settings';

const GetCompanyDetailDocument = graphql(/* GraphQL */ `
    query GetCompanyDetail($id: ID!) {
        getCompany(id: $id) {
            id
            name
            email
        }
    }
`);

const TABS: TabItemConfig[] = [
    {
        id: 'subscription',
        label: 'Subscription',
    },
    {
        id: 'accountingData',
        label: 'Accounting Data',
    },
    {
        id: 'targeting',
        label: 'Targeting',
    },
    {
        id: 'userManagement',
        label: 'Users',
    },
    {
        id: 'featureFlags',
        label: 'Feature Flags',
    },
    {
        id: 'settings',
        label: 'Settings',
    },
    {
        id: 'debugging',
        label: 'Debugging',
    },
];

const CompanyDetail: React.FC = () => {
    const {companyId} = useParams();
    const {loading, error, data} = useQuery(GetCompanyDetailDocument, {variables: {id: companyId}});
    const [selectedTab, setSelectedTab] = React.useState(TABS[0]);
    return (
        <View>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <VStack>
                    <div>
                        <Heading1>{data.getCompany.name ?? ''}</Heading1>
                        <DetailText size="small" inline={false}>
                            <pre>{data.getCompany.id}</pre>
                        </DetailText>
                        <DetailText inline={false}>{data.getCompany.email}</DetailText>
                    </div>
                    <TabBar tabs={TABS} selectedTab={selectedTab} onSelectTab={setSelectedTab} />
                    {selectedTab.id === 'subscription' && (
                        <CompanySubscriptionData companyAccountId={companyId} />
                    )}
                    {selectedTab.id === 'accountingData' && (
                        <CompanyAccountingData companyAccountId={companyId} />
                    )}
                    {selectedTab.id === 'targeting' && (
                        <CompanyTargetingData companyAccountId={companyId} />
                    )}
                    {selectedTab.id === 'userManagement' && (
                        <UserManagement companyAccountId={companyId} />
                    )}
                    {selectedTab.id === 'featureFlags' && (
                        <FeatureFlags companyAccountId={companyId} />
                    )}
                    {selectedTab.id === 'settings' && <Settings companyAccountId={companyId} />}
                    {selectedTab.id === 'debugging' && <Debugging companyAccountId={companyId} />}
                </VStack>
            )}
        </View>
    );
};

export {CompanyDetail};
