import {useMutation} from '@apollo/client';
import React from 'react';

import {Button, DetailText, Heading2, HStack, VStack} from '@sphericsio/design-system';

import {AlertIcon} from '../../icons';
import {graphql} from '../../../graphql/generated';
import {GetReductionTargetDocument} from './company-targeting-data';

const resetReductionTarget = graphql(/* GraphQL */ `
    mutation ResetReductionTarget($id: ID!) {
        resetReductionTarget(id: $id) {
            id
        }
    }
`);

type ReductionTarget = {
    baseline_year: number;
    target_completion_year: number;
};

type CompanyTargetingDataDetailsProps = {
    target?: ReductionTarget | null;
    companyAccountId: string;
};

export const CompanyTargetingDataDetails = ({
    target,
    companyAccountId,
}: CompanyTargetingDataDetailsProps) => {
    const [reset, {loading}] = useMutation(resetReductionTarget, {
        refetchQueries: [{query: GetReductionTargetDocument, variables: {id: companyAccountId}}],
    });
    const onResetPress = React.useCallback(() => {
        const ok = confirm('Do you really want to reset the net zero target for this company?');
        if (ok) {
            reset({variables: {id: companyAccountId}});
        }
    }, [reset, companyAccountId]);
    return (
        <VStack>
            <Heading2>Targeting data</Heading2>
            {!target && <DetailText>No reduction target set</DetailText>}
            {target && (
                <>
                    <HStack>
                        <DetailText inline={false}>Baseline year:</DetailText>
                        <DetailText bold>{target.baseline_year}</DetailText>
                    </HStack>
                    <HStack>
                        <DetailText inline={false}>Net zero target:</DetailText>
                        <DetailText bold>{target.target_completion_year}</DetailText>
                        <Button
                            icon={<AlertIcon />}
                            isLoading={loading}
                            bg="error"
                            onPress={onResetPress}
                        >
                            Reset net zero target
                        </Button>
                    </HStack>
                </>
            )}
        </VStack>
    );
};
