import React from 'react';
import * as Yup from 'yup';
import {useMutation} from '@apollo/client';

import {VStack, Heading3, Button, Heading4} from '@sphericsio/design-system';
import {Input, Form, formCtx} from '@sphericsio/forms';
import {getErrorMessage} from '@sphericsio/mvp-ui-common';

import {graphql} from '../graphql/generated';
import {ErrorCard} from './error-card';
import {FormSection} from './forms/form-section';
import {ToggleWithLabel} from './forms/toggle-with-label';

type AddCompanyRequest = {
    companyName: string;
    email: string;
    name: string;
    isFreeForLife: boolean;
    isSagePilotUser: boolean;
    isSage50User: boolean;
};

const AddCompany = graphql(/* GraphQL */ `
    mutation AddCompany($company: AddCompanyInput!) {
        addCompany(addCompanyInput: $company) {
            id
        }
    }
`);

const ERROR_CODES: Record<string, string> = {
    USER_EXISTS: 'A user with this email address already exists',
};

const schema = Yup.object({
    companyName: Yup.string().required("Please enter the company's name"),
    email: Yup.string()
        .email('Please enter a valid email address')
        .required("Please enter the user's email address"),
    name: Yup.string().required("Please enter the user's email"),
});

type AddCompanyFormProps = {
    onDone?: () => unknown;
};

export function AddCompanyForm({onDone}: AddCompanyFormProps) {
    const ctx = formCtx<AddCompanyRequest>();
    const [addCompany, {loading, error}] = useMutation(AddCompany);

    function onChange(newVals: AddCompanyRequest, oldVals: AddCompanyRequest) {
        if (newVals.isSagePilotUser !== oldVals.isSagePilotUser) {
            ctx.current.setFieldValue('isFreeForLife', newVals.isSagePilotUser);
        }
    }

    async function onSubmit(values: AddCompanyRequest) {
        await addCompany({
            variables: {
                company: values,
            },
        });
        onDone && onDone();
    }

    return (
        <div className="text-left">
            <VStack>
                <Heading3>Add a new company.</Heading3>
                <Form<AddCompanyRequest>
                    initialValues={{
                        companyName: '',
                        email: '',
                        name: '',
                        isFreeForLife: true,
                        isSagePilotUser: true,
                        isSage50User: false,
                    }}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                    onChange={onChange}
                    ctx={ctx}
                >
                    <FormSection>
                        <Heading4>Basic details</Heading4>
                        <Input name="companyName" placeholder="Company name" />
                        <Input name="name" placeholder="Full name" />
                        <Input name="email" type="email" placeholder="Email address" />
                    </FormSection>

                    <FormSection>
                        <Heading4>Options</Heading4>

                        <ToggleWithLabel title="Sage pilot user?" name="isSagePilotUser" />

                        <ToggleWithLabel title="Free for life?" name="isFreeForLife" />
                        <ToggleWithLabel title="Sage50 feature available?" name="isSage50User" />
                    </FormSection>

                    {error && <ErrorCard message={getErrorMessage(error, ERROR_CODES)} />}

                    <FormSection>
                        <Button isLoading={loading} type="submit">
                            Add company and send invite email
                        </Button>
                    </FormSection>
                </Form>
            </VStack>
        </div>
    );
}
