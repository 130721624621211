import {graphql} from './generated';

export const CurrentUserFragmentDocument = graphql(/* GraphQL */ `
    fragment CurrentUser on User {
        id
        name
        email
        company_name
    }
`);
