import React from 'react';
import {useQuery} from '@apollo/client';

import {Heading2, VStack} from '@sphericsio/design-system';

import {View} from '../components/view';
import {Table, TBody, TData, THead, THeader, TRow} from '../components/table';
import {graphql} from '../graphql/generated';
import {Loading} from '../components/loading';
import {ErrorCard} from '../components/error-card';

const GET_ADMIN_USERS_QUERY = graphql(/* GraphQL */ `
    query GetAdminUsers {
        getAdminUsers {
            id
            name
            email
        }
    }
`);

export function Users() {
    const {loading, error, data} = useQuery(GET_ADMIN_USERS_QUERY);

    return (
        <View>
            <VStack>
                <Heading2>Users</Heading2>
                <Table>
                    <THead>
                        <TRow>
                            <THeader>Name</THeader>
                            <THeader>Email</THeader>
                        </TRow>
                    </THead>
                    <TBody>
                        {loading && <Loading />}
                        {error && <ErrorCard />}
                        {data &&
                            data.getAdminUsers.map((user) => (
                                <TRow key={user.id}>
                                    <TData>{user.name}</TData>
                                    <TData>{user.email}</TData>
                                </TRow>
                            ))}
                        {data && data.getAdminUsers.length === 0 && (
                            <TRow>
                                <TData colSpan={3}>No users</TData>
                            </TRow>
                        )}
                    </TBody>
                </Table>
            </VStack>
        </View>
    );
}
