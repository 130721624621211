import React from 'react';

export function InfoIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block"
        >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12.01" y2="8"></line>
        </svg>
    );
}

export function RepeatIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block"
        >
            <polyline points="17 1 21 5 17 9"></polyline>
            <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
            <polyline points="7 23 3 19 7 15"></polyline>
            <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
        </svg>
    );
}

export function CheckIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block"
        >
            <polyline points="20 6 9 17 4 12"></polyline>
        </svg>
    );
}

export function AlertIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block"
        >
            <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
            <line x1="12" y1="9" x2="12" y2="13"></line>
            <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
    );
}

export function ChevronLeftIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="inline-block"
        >
            <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
    );
}

export function CalendarIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <rect
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="32"
                x="48"
                y="80"
                width="416"
                height="384"
                rx="48"
            />
            <g fill="currentColor">
                <circle cx="296" cy="232" r="24" />
                <circle cx="376" cy="232" r="24" />
                <circle cx="296" cy="312" r="24" />
                <circle cx="376" cy="312" r="24" />
                <circle cx="136" cy="312" r="24" />
                <circle cx="216" cy="312" r="24" />
                <circle cx="136" cy="392" r="24" />
                <circle cx="216" cy="392" r="24" />
                <circle cx="296" cy="392" r="24" />
            </g>
            <path
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="32"
                strokeLinecap="round"
                d="M128 48v32M384 48v32"
            />
            <path
                fill="none"
                stroke="currentColor"
                strokeLinejoin="round"
                strokeWidth="32"
                d="M464 160H48"
            />
        </svg>
    );
}
