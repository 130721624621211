/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n    mutation AddCompany($company: AddCompanyInput!) {\n        addCompany(addCompanyInput: $company) {\n            id\n        }\n    }\n": types.AddCompanyDocument,
    "\n    mutation IdentityProviderCallback($params: String!, $provider: IdentityProviderName!) {\n        identityProviderCallback(params: $params, provider: $provider) {\n            id\n        }\n    }\n": types.IdentityProviderCallbackDocument,
    "\n    query GetCompanyAccountingData($id: ID!) {\n        getCompany(id: $id) {\n            id\n            financialDataQuality {\n                totalTransactions\n                totalMerchants\n                transactionsWithoutMerchants {\n                    id\n                    description\n                    transaction_date\n                    amount\n                }\n            }\n            financialProviderSyncStatus {\n                id\n                created_at\n                completed_at\n                sync_type\n                sync_method\n                sync_status\n            }\n        }\n    }\n": types.GetCompanyAccountingDataDocument,
    "\n    mutation ForceCompleteProviderResync($companyAccountId: ID!, $financialProvider: String!) {\n        forceCompleteFinancialProviderResync(\n            companyAccountId: $companyAccountId\n            financialProvider: $financialProvider\n        ) {\n            id\n        }\n    }\n": types.ForceCompleteProviderResyncDocument,
    "\n    mutation ResetCompanyData($id: ID!) {\n        resetCompanyData(id: $id) {\n            id\n        }\n    }\n": types.ResetCompanyDataDocument,
    "\n    mutation DisconnectCompanyFinancialProvider($companyAccountId: String!) {\n        disconnectCompanyFinancialProvider(companyAccountId: $companyAccountId) {\n            id\n            sync_type\n            sync_status\n            created_at\n        }\n    }\n": types.DisconnectCompanyFinancialProviderDocument,
    "\n    mutation RefreshCompanyTransactionData($id: ID!, $target: EmissionsRefreshTarget!) {\n        refreshCompanyTransactions(id: $id, target: $target) {\n            id\n        }\n    }\n": types.RefreshCompanyTransactionDataDocument,
    "\n    mutation AddUser($user: AddUserInput!) {\n        addUser(addUserInput: $user) {\n            id\n        }\n    }\n": types.AddUserDocument,
    "\n    query GetAccount($companyAccountId: String!, $financialAccountId: String!) {\n        getAccount(companyAccountId: $companyAccountId, financialAccountId: $financialAccountId) {\n            id\n            name\n            description\n        }\n    }\n": types.GetAccountDocument,
    "\n    mutation DisableUser($user: ID!) {\n        disableUser(userAccountId: $user) {\n            id\n        }\n    }\n": types.DisableUserDocument,
    "\n    query GetCompanyFeatureFlags($companyAccountId: ID!) {\n        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: feature_flag) {\n            id\n            name\n            description\n            enabled\n            reversible\n        }\n    }\n": types.GetCompanyFeatureFlagsDocument,
    "\n    mutation EnableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {\n        enableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {\n            id\n        }\n    }\n": types.EnableCompanyFeatureFlagDocument,
    "\n    mutation DisableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {\n        disableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {\n            id\n        }\n    }\n": types.DisableCompanyFeatureFlagDocument,
    "\n    query GetCompanySettings($companyAccountId: ID!) {\n        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: setting) {\n            id\n            name\n            description\n            enabled\n            reversible\n        }\n    }\n": types.GetCompanySettingsDocument,
    "\n    query GetCompanySubscription($id: ID!) {\n        getCompany(id: $id) {\n            id\n            subscription {\n                stripeSubscriptionId\n                isTrial\n                trialPeriodEnds\n                status\n            }\n        }\n    }\n": types.GetCompanySubscriptionDocument,
    "\n    mutation ExtendSubscriptionTrial($companyAccountId: ID!, $trialEnd: DateTime!) {\n        extendSubscriptionTrial(companyAccountId: $companyAccountId, trialEnd: $trialEnd) {\n            stripeSubscriptionId\n            isTrial\n            trialPeriodEnds\n        }\n    }\n": types.ExtendSubscriptionTrialDocument,
    "\n    mutation CancelCompanySubscription($companyAccountId: ID!) {\n        cancelCompanySubscription(companyAccountId: $companyAccountId) {\n            id\n        }\n    }\n": types.CancelCompanySubscriptionDocument,
    "\n    mutation SwitchUserToFinancialProviderLogin($userEmailAddress: String!) {\n        switchUserToFinancialProviderLogin(userEmailAddress: $userEmailAddress) {\n            id\n        }\n    }\n": types.SwitchUserToFinancialProviderLoginDocument,
    "\n    mutation ResetTargetingBaseline($baselineId: ID!) {\n        resetTargetingBaseline(baselineId: $baselineId)\n    }\n": types.ResetTargetingBaselineDocument,
    "\n    query GetReductionTarget($id: ID!) {\n        getReductionTarget(id: $id) {\n            reduction_target_type\n            baseline_year\n            target_completion_year\n            yearly_percent_reduction\n        }\n\n        getTargetingBaseline(id: $id) {\n            id\n            company_reporting_period {\n                period_start\n                period_end\n            }\n        }\n    }\n": types.GetReductionTargetDocument,
    "\n    mutation RemoveOverlappingBaselineOpsProfile($id: ID!) {\n        removeOverlappingBaselineOpsProfile(id: $id)\n    }\n": types.RemoveOverlappingBaselineOpsProfileDocument,
    "\n    query GetOverlappingBaselineOpsProfile($id: ID!) {\n        getOverlappingOpsBaselineProfile(id: $id) {\n            baseline_profile_id\n            baseline_period_start\n            baseline_period_end\n            ops_profile_id\n            ops_period_start\n            ops_period_end\n            ops_reporting_period_id\n            profile_type\n        }\n    }\n": types.GetOverlappingBaselineOpsProfileDocument,
    "\n    mutation ResetReductionTarget($id: ID!) {\n        resetReductionTarget(id: $id) {\n            id\n        }\n    }\n": types.ResetReductionTargetDocument,
    "\n    query GetCompanyUsers($companyAccountId: ID!) {\n        getCompanyUsers(companyAccountId: $companyAccountId) {\n            id\n            name\n            email\n            roles\n            enabled\n        }\n    }\n": types.GetCompanyUsersDocument,
    "\n    mutation ResendInvite($email: String!, $companyAccountId: ID!) {\n        resendCompanyUserInvite(userEmail: $email, companyAccountId: $companyAccountId) {\n            code\n            expires_at\n        }\n    }\n": types.ResendInviteDocument,
    "\n    fragment TaxonomyTermDisplay_Taxonomy on Taxonomy {\n        id\n        name\n        terms {\n            id\n            name\n        }\n    }\n": types.TaxonomyTermDisplay_TaxonomyFragmentDoc,
    "\n    query GetCurrentUser {\n        currentUser {\n            ...CurrentUser\n        }\n    }\n": types.GetCurrentUserDocument,
    "\n    fragment CurrentUser on User {\n        id\n        name\n        email\n        company_name\n    }\n": types.CurrentUserFragmentDoc,
    "\n    query FetchBackgroundExecutionStatus($id: ID!) {\n        fetchBackgroundExecution(id: $id) {\n            status\n            completed_at\n        }\n    }\n": types.FetchBackgroundExecutionStatusDocument,
    "\n    query GetNewCompanies {\n        getNewCompanies {\n            id\n            name\n        }\n    }\n": types.GetNewCompaniesDocument,
    "\n    query SearchForCompany($query: String!) {\n        searchForCompany(query: $query) {\n            id\n            name\n        }\n    }\n": types.SearchForCompanyDocument,
    "\n    query GetCompanyDetail($id: ID!) {\n        getCompany(id: $id) {\n            id\n            name\n            email\n        }\n    }\n": types.GetCompanyDetailDocument,
    "\n    query GetStuckSyncs {\n        getStuckSyncs {\n            id\n            created_at\n            sync_type\n            sync_method\n            company_account {\n                name\n            }\n        }\n    }\n": types.GetStuckSyncsDocument,
    "\n    mutation ClearStuckSync($id: ID!) {\n        clearStuckSync(id: $id) {\n            id\n            created_at\n            sync_type\n            sync_method\n            company_account {\n                name\n            }\n        }\n    }\n": types.ClearStuckSyncDocument,
    "\n    mutation GetIdentityProviderUrl {\n        getIdentityProviderUrl {\n            url\n        }\n    }\n": types.GetIdentityProviderUrlDocument,
    "\n    mutation Logout {\n        logout\n    }\n": types.LogoutDocument,
    "\n    query GetAdminUsers {\n        getAdminUsers {\n            id\n            name\n            email\n        }\n    }\n": types.GetAdminUsersDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation AddCompany($company: AddCompanyInput!) {\n        addCompany(addCompanyInput: $company) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddCompany($company: AddCompanyInput!) {\n        addCompany(addCompanyInput: $company) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation IdentityProviderCallback($params: String!, $provider: IdentityProviderName!) {\n        identityProviderCallback(params: $params, provider: $provider) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation IdentityProviderCallback($params: String!, $provider: IdentityProviderName!) {\n        identityProviderCallback(params: $params, provider: $provider) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetCompanyAccountingData($id: ID!) {\n        getCompany(id: $id) {\n            id\n            financialDataQuality {\n                totalTransactions\n                totalMerchants\n                transactionsWithoutMerchants {\n                    id\n                    description\n                    transaction_date\n                    amount\n                }\n            }\n            financialProviderSyncStatus {\n                id\n                created_at\n                completed_at\n                sync_type\n                sync_method\n                sync_status\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetCompanyAccountingData($id: ID!) {\n        getCompany(id: $id) {\n            id\n            financialDataQuality {\n                totalTransactions\n                totalMerchants\n                transactionsWithoutMerchants {\n                    id\n                    description\n                    transaction_date\n                    amount\n                }\n            }\n            financialProviderSyncStatus {\n                id\n                created_at\n                completed_at\n                sync_type\n                sync_method\n                sync_status\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ForceCompleteProviderResync($companyAccountId: ID!, $financialProvider: String!) {\n        forceCompleteFinancialProviderResync(\n            companyAccountId: $companyAccountId\n            financialProvider: $financialProvider\n        ) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ForceCompleteProviderResync($companyAccountId: ID!, $financialProvider: String!) {\n        forceCompleteFinancialProviderResync(\n            companyAccountId: $companyAccountId\n            financialProvider: $financialProvider\n        ) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ResetCompanyData($id: ID!) {\n        resetCompanyData(id: $id) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ResetCompanyData($id: ID!) {\n        resetCompanyData(id: $id) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DisconnectCompanyFinancialProvider($companyAccountId: String!) {\n        disconnectCompanyFinancialProvider(companyAccountId: $companyAccountId) {\n            id\n            sync_type\n            sync_status\n            created_at\n        }\n    }\n"): (typeof documents)["\n    mutation DisconnectCompanyFinancialProvider($companyAccountId: String!) {\n        disconnectCompanyFinancialProvider(companyAccountId: $companyAccountId) {\n            id\n            sync_type\n            sync_status\n            created_at\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation RefreshCompanyTransactionData($id: ID!, $target: EmissionsRefreshTarget!) {\n        refreshCompanyTransactions(id: $id, target: $target) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation RefreshCompanyTransactionData($id: ID!, $target: EmissionsRefreshTarget!) {\n        refreshCompanyTransactions(id: $id, target: $target) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation AddUser($user: AddUserInput!) {\n        addUser(addUserInput: $user) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation AddUser($user: AddUserInput!) {\n        addUser(addUserInput: $user) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetAccount($companyAccountId: String!, $financialAccountId: String!) {\n        getAccount(companyAccountId: $companyAccountId, financialAccountId: $financialAccountId) {\n            id\n            name\n            description\n        }\n    }\n"): (typeof documents)["\n    query GetAccount($companyAccountId: String!, $financialAccountId: String!) {\n        getAccount(companyAccountId: $companyAccountId, financialAccountId: $financialAccountId) {\n            id\n            name\n            description\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DisableUser($user: ID!) {\n        disableUser(userAccountId: $user) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation DisableUser($user: ID!) {\n        disableUser(userAccountId: $user) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetCompanyFeatureFlags($companyAccountId: ID!) {\n        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: feature_flag) {\n            id\n            name\n            description\n            enabled\n            reversible\n        }\n    }\n"): (typeof documents)["\n    query GetCompanyFeatureFlags($companyAccountId: ID!) {\n        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: feature_flag) {\n            id\n            name\n            description\n            enabled\n            reversible\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation EnableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {\n        enableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation EnableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {\n        enableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation DisableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {\n        disableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation DisableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {\n        disableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetCompanySettings($companyAccountId: ID!) {\n        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: setting) {\n            id\n            name\n            description\n            enabled\n            reversible\n        }\n    }\n"): (typeof documents)["\n    query GetCompanySettings($companyAccountId: ID!) {\n        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: setting) {\n            id\n            name\n            description\n            enabled\n            reversible\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetCompanySubscription($id: ID!) {\n        getCompany(id: $id) {\n            id\n            subscription {\n                stripeSubscriptionId\n                isTrial\n                trialPeriodEnds\n                status\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetCompanySubscription($id: ID!) {\n        getCompany(id: $id) {\n            id\n            subscription {\n                stripeSubscriptionId\n                isTrial\n                trialPeriodEnds\n                status\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ExtendSubscriptionTrial($companyAccountId: ID!, $trialEnd: DateTime!) {\n        extendSubscriptionTrial(companyAccountId: $companyAccountId, trialEnd: $trialEnd) {\n            stripeSubscriptionId\n            isTrial\n            trialPeriodEnds\n        }\n    }\n"): (typeof documents)["\n    mutation ExtendSubscriptionTrial($companyAccountId: ID!, $trialEnd: DateTime!) {\n        extendSubscriptionTrial(companyAccountId: $companyAccountId, trialEnd: $trialEnd) {\n            stripeSubscriptionId\n            isTrial\n            trialPeriodEnds\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation CancelCompanySubscription($companyAccountId: ID!) {\n        cancelCompanySubscription(companyAccountId: $companyAccountId) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation CancelCompanySubscription($companyAccountId: ID!) {\n        cancelCompanySubscription(companyAccountId: $companyAccountId) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation SwitchUserToFinancialProviderLogin($userEmailAddress: String!) {\n        switchUserToFinancialProviderLogin(userEmailAddress: $userEmailAddress) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation SwitchUserToFinancialProviderLogin($userEmailAddress: String!) {\n        switchUserToFinancialProviderLogin(userEmailAddress: $userEmailAddress) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ResetTargetingBaseline($baselineId: ID!) {\n        resetTargetingBaseline(baselineId: $baselineId)\n    }\n"): (typeof documents)["\n    mutation ResetTargetingBaseline($baselineId: ID!) {\n        resetTargetingBaseline(baselineId: $baselineId)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetReductionTarget($id: ID!) {\n        getReductionTarget(id: $id) {\n            reduction_target_type\n            baseline_year\n            target_completion_year\n            yearly_percent_reduction\n        }\n\n        getTargetingBaseline(id: $id) {\n            id\n            company_reporting_period {\n                period_start\n                period_end\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetReductionTarget($id: ID!) {\n        getReductionTarget(id: $id) {\n            reduction_target_type\n            baseline_year\n            target_completion_year\n            yearly_percent_reduction\n        }\n\n        getTargetingBaseline(id: $id) {\n            id\n            company_reporting_period {\n                period_start\n                period_end\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation RemoveOverlappingBaselineOpsProfile($id: ID!) {\n        removeOverlappingBaselineOpsProfile(id: $id)\n    }\n"): (typeof documents)["\n    mutation RemoveOverlappingBaselineOpsProfile($id: ID!) {\n        removeOverlappingBaselineOpsProfile(id: $id)\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetOverlappingBaselineOpsProfile($id: ID!) {\n        getOverlappingOpsBaselineProfile(id: $id) {\n            baseline_profile_id\n            baseline_period_start\n            baseline_period_end\n            ops_profile_id\n            ops_period_start\n            ops_period_end\n            ops_reporting_period_id\n            profile_type\n        }\n    }\n"): (typeof documents)["\n    query GetOverlappingBaselineOpsProfile($id: ID!) {\n        getOverlappingOpsBaselineProfile(id: $id) {\n            baseline_profile_id\n            baseline_period_start\n            baseline_period_end\n            ops_profile_id\n            ops_period_start\n            ops_period_end\n            ops_reporting_period_id\n            profile_type\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ResetReductionTarget($id: ID!) {\n        resetReductionTarget(id: $id) {\n            id\n        }\n    }\n"): (typeof documents)["\n    mutation ResetReductionTarget($id: ID!) {\n        resetReductionTarget(id: $id) {\n            id\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetCompanyUsers($companyAccountId: ID!) {\n        getCompanyUsers(companyAccountId: $companyAccountId) {\n            id\n            name\n            email\n            roles\n            enabled\n        }\n    }\n"): (typeof documents)["\n    query GetCompanyUsers($companyAccountId: ID!) {\n        getCompanyUsers(companyAccountId: $companyAccountId) {\n            id\n            name\n            email\n            roles\n            enabled\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ResendInvite($email: String!, $companyAccountId: ID!) {\n        resendCompanyUserInvite(userEmail: $email, companyAccountId: $companyAccountId) {\n            code\n            expires_at\n        }\n    }\n"): (typeof documents)["\n    mutation ResendInvite($email: String!, $companyAccountId: ID!) {\n        resendCompanyUserInvite(userEmail: $email, companyAccountId: $companyAccountId) {\n            code\n            expires_at\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment TaxonomyTermDisplay_Taxonomy on Taxonomy {\n        id\n        name\n        terms {\n            id\n            name\n        }\n    }\n"): (typeof documents)["\n    fragment TaxonomyTermDisplay_Taxonomy on Taxonomy {\n        id\n        name\n        terms {\n            id\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetCurrentUser {\n        currentUser {\n            ...CurrentUser\n        }\n    }\n"): (typeof documents)["\n    query GetCurrentUser {\n        currentUser {\n            ...CurrentUser\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    fragment CurrentUser on User {\n        id\n        name\n        email\n        company_name\n    }\n"): (typeof documents)["\n    fragment CurrentUser on User {\n        id\n        name\n        email\n        company_name\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query FetchBackgroundExecutionStatus($id: ID!) {\n        fetchBackgroundExecution(id: $id) {\n            status\n            completed_at\n        }\n    }\n"): (typeof documents)["\n    query FetchBackgroundExecutionStatus($id: ID!) {\n        fetchBackgroundExecution(id: $id) {\n            status\n            completed_at\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetNewCompanies {\n        getNewCompanies {\n            id\n            name\n        }\n    }\n"): (typeof documents)["\n    query GetNewCompanies {\n        getNewCompanies {\n            id\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query SearchForCompany($query: String!) {\n        searchForCompany(query: $query) {\n            id\n            name\n        }\n    }\n"): (typeof documents)["\n    query SearchForCompany($query: String!) {\n        searchForCompany(query: $query) {\n            id\n            name\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetCompanyDetail($id: ID!) {\n        getCompany(id: $id) {\n            id\n            name\n            email\n        }\n    }\n"): (typeof documents)["\n    query GetCompanyDetail($id: ID!) {\n        getCompany(id: $id) {\n            id\n            name\n            email\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetStuckSyncs {\n        getStuckSyncs {\n            id\n            created_at\n            sync_type\n            sync_method\n            company_account {\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    query GetStuckSyncs {\n        getStuckSyncs {\n            id\n            created_at\n            sync_type\n            sync_method\n            company_account {\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation ClearStuckSync($id: ID!) {\n        clearStuckSync(id: $id) {\n            id\n            created_at\n            sync_type\n            sync_method\n            company_account {\n                name\n            }\n        }\n    }\n"): (typeof documents)["\n    mutation ClearStuckSync($id: ID!) {\n        clearStuckSync(id: $id) {\n            id\n            created_at\n            sync_type\n            sync_method\n            company_account {\n                name\n            }\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation GetIdentityProviderUrl {\n        getIdentityProviderUrl {\n            url\n        }\n    }\n"): (typeof documents)["\n    mutation GetIdentityProviderUrl {\n        getIdentityProviderUrl {\n            url\n        }\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation Logout {\n        logout\n    }\n"): (typeof documents)["\n    mutation Logout {\n        logout\n    }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query GetAdminUsers {\n        getAdminUsers {\n            id\n            name\n            email\n        }\n    }\n"): (typeof documents)["\n    query GetAdminUsers {\n        getAdminUsers {\n            id\n            name\n            email\n        }\n    }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;