import {PartialConfiguration} from '@sphericsio/config';

import {Config} from './default';

const config: PartialConfiguration<Config> = {
    apiHost: 'https://api.integration.dev.spherics.io',
    bugsnag: {
        enabled: true,
    },
};
export default config;
