import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import {formatDistanceToNow, parseJSON} from 'date-fns';

import {Button, Heading2, VStack} from '@sphericsio/design-system';

import {View} from '../components/view';
import {Table, THead, THeader, TRow, TBody, TData} from '../components/table';
import {Loading} from '../components/loading';
import {ErrorCard} from '../components/error-card';
import {graphql} from '../graphql/generated';

export const GetStuckSyncsDocument = graphql(/* GraphQL */ `
    query GetStuckSyncs {
        getStuckSyncs {
            id
            created_at
            sync_type
            sync_method
            company_account {
                name
            }
        }
    }
`);

export const ClearStuckSyncDocument = graphql(/* GraphQL */ `
    mutation ClearStuckSync($id: ID!) {
        clearStuckSync(id: $id) {
            id
            created_at
            sync_type
            sync_method
            company_account {
                name
            }
        }
    }
`);

export function FinancialProviders() {
    const {loading, error, data} = useQuery(GetStuckSyncsDocument);
    const [clearStuckSync, {loading: submitting, error: errorSubmitting, data: submitted}] =
        useMutation(ClearStuckSyncDocument);
    const [idToClear, setIdToClear] = React.useState<string>();
    React.useEffect(() => {
        if (submitted != null) {
            setIdToClear(undefined);
        }
    }, [submitted, setIdToClear]);
    return (
        <View>
            <VStack>
                <div>
                    <Heading2>Stuck Syncs</Heading2>
                </div>
                {loading && <Loading />}
                {error && <ErrorCard />}
                {data && (
                    <Table>
                        <THead>
                            <TRow>
                                <THeader>ID</THeader>
                                <THeader>Sync Started At</THeader>
                                <THeader>Sync Method</THeader>
                                <THeader>Financial Provider</THeader>
                                <THeader>Company Name</THeader>
                                <THeader>Action</THeader>
                            </TRow>
                        </THead>
                        <TBody>
                            {data.getStuckSyncs.map((sync) => (
                                <TRow key={sync.id}>
                                    <TData>{sync.id}</TData>
                                    <TData>
                                        <div className="cursor-help" title={sync.created_at}>
                                            <time dateTime={sync.created_at}>
                                                {formatDistanceToNow(parseJSON(sync.created_at), {
                                                    addSuffix: true,
                                                })}
                                            </time>
                                        </div>
                                    </TData>
                                    <TData>{sync.sync_method}</TData>
                                    <TData>{sync.sync_type}</TData>
                                    <TData>{sync.company_account.name}</TData>
                                    <TData>
                                        <Button
                                            isDisabled={submitting}
                                            isLoading={submitting && idToClear === sync.id}
                                            onPress={() => {
                                                setIdToClear(sync.id);
                                                clearStuckSync({
                                                    variables: {id: sync.id},
                                                    refetchQueries: [
                                                        {query: GetStuckSyncsDocument},
                                                    ],
                                                });
                                            }}
                                        >
                                            Clear Sync
                                        </Button>
                                    </TData>
                                </TRow>
                            ))}
                            {data.getStuckSyncs.length === 0 && (
                                <TRow>
                                    <TData colSpan={5}>No stuck syncs.</TData>
                                </TRow>
                            )}
                        </TBody>
                    </Table>
                )}
                {errorSubmitting && <ErrorCard />}
            </VStack>
        </View>
    );
}
