import React from 'react';
import {useMutation} from '@apollo/client';

import {Button, DetailText, Panel, VStack} from '@sphericsio/design-system';

import {graphql} from '../graphql/generated';

export const GetIdentityProviderUrlDocument = graphql(/* GraphQL */ `
    mutation GetIdentityProviderUrl {
        getIdentityProviderUrl {
            url
        }
    }
`);

const Login: React.FC = () => {
    const [getAuthorisationUrl, {loading: urlLoading, error: identityError, data}] = useMutation(
        GetIdentityProviderUrlDocument,
    );

    React.useEffect(() => {
        if (data?.getIdentityProviderUrl?.url != null) {
            window.location.href = data.getIdentityProviderUrl.url;
        }
    }, [data?.getIdentityProviderUrl?.url]);

    return (
        <div className="flex mt-10 justify-center">
            <div className="w-1/3">
                <Panel>
                    <VStack>
                        {identityError && (
                            <DetailText colour="error">Something went wrong</DetailText>
                        )}
                        <Button onPress={() => getAuthorisationUrl()} isLoading={urlLoading}>
                            Log in with Sage ID
                        </Button>
                    </VStack>
                </Panel>
            </div>
        </div>
    );
};

export {Login};
