import React from 'react';

import {DetailText, HStack, LoadingSpinner, Panel} from '@sphericsio/design-system';

type LoadingProps = {
    message?: string;
};

const Loading: React.FC<LoadingProps> = ({message}) => {
    return (
        <Panel>
            <HStack>
                <LoadingSpinner />
                <DetailText>{message || 'Please wait...'}</DetailText>
            </HStack>
        </Panel>
    );
};

export {Loading};
