import {useMutation} from '@apollo/client';
import * as Yup from 'yup';
import React from 'react';

import {Form, Input} from '@sphericsio/forms';
import {Button, Heading3, VStack} from '@sphericsio/design-system';
import {getErrorMessage} from '@sphericsio/mvp-ui-common';

import {FormSection} from '../forms/form-section';
import {ToggleWithLabel} from '../forms/toggle-with-label';
import {ErrorCard} from '../error-card';
import {graphql} from '../../graphql/generated';

const ERROR_CODES: Record<string, string> = {
    USER_EXISTS: 'A user with this email address already exists',
};

const schema = Yup.object({
    name: Yup.string().required("Please enter the user's name"),
    email: Yup.string()
        .email('Please enter a valid email address')
        .required("Please enter the user's email address"),
    isCompanyAdmin: Yup.boolean(),
});

const AddUser = graphql(/* GraphQL */ `
    mutation AddUser($user: AddUserInput!) {
        addUser(addUserInput: $user) {
            id
        }
    }
`);

type AddUserForm = {
    name: string;
    email: string;
    isCompanyAdmin: boolean;
};

export function AddUserForm({
    onDone,
    companyAccountId,
}: {
    onDone: () => unknown;
    companyAccountId: string;
}) {
    const [addUser, {loading, error}] = useMutation(AddUser);

    async function onSubmit({name, email, isCompanyAdmin}: AddUserForm) {
        await addUser({
            variables: {
                user: {
                    name,
                    email,
                    companyAccountId,
                    roles: ['user', ...(isCompanyAdmin ? ['company-admin'] : [])],
                },
            },
        });
        onDone && onDone();
    }
    return (
        <div className="text-left">
            <VStack>
                <Heading3>Add a new user.</Heading3>
                <Form<AddUserForm>
                    initialValues={{
                        name: '',
                        email: '',
                        isCompanyAdmin: false,
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                >
                    <FormSection>
                        <Input name="name" placeholder="Full name" />
                        <Input name="email" type="email" placeholder="Email address" />
                        <ToggleWithLabel title="Company admin?" name="isCompanyAdmin" />
                    </FormSection>
                    {error && <ErrorCard message={getErrorMessage(error, ERROR_CODES)} />}

                    <FormSection>
                        <Button isLoading={loading} type="submit">
                            Add user and send invite email
                        </Button>
                    </FormSection>
                </Form>
            </VStack>
        </div>
    );
    return <p>Hello</p>;
}
