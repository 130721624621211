import React from 'react';
import {useMatch} from 'react-router-dom';

import {DetailText, HStack} from '@sphericsio/design-system';

import {Link} from './link';
import {useCurrentUser} from './user-context';
import logo from '../images/logo.svg';

type NavButtonProps = {
    children: React.ReactNode;
    to: string;
    exact?: boolean;
};

const NavButton: React.FC<NavButtonProps> = ({to, exact, children}) => {
    const active = useMatch({path: to, end: exact == null ? false : exact});
    return (
        <Link active={active != null} to={to}>
            {children}
        </Link>
    );
};

const Layout: React.FC<{children: React.ReactNode}> = ({children}) => {
    const user = useCurrentUser();

    return (
        <div className="flex flex-1 flex-col">
            <nav className="flex py-1 px-3 items-center">
                <Link to="/">
                    <img src={logo} width={200} />
                </Link>
                {user && (
                    <>
                        <div className="flex flex-1 px-4">
                            <HStack>
                                <NavButton to="/companies">Companies</NavButton>
                                <NavButton to="/users">Admin Users</NavButton>
                                <NavButton to="/financial-providers">Financial Providers</NavButton>
                            </HStack>
                        </div>
                        <div className="flex flex-col">
                            <DetailText>{user.name}</DetailText>
                            <NavButton to="/logout">Logout</NavButton>
                        </div>
                    </>
                )}
            </nav>
            <div className="ml-3">
                <DetailText>ADMIN {BUILD_NUMBER ? `Build ${BUILD_NUMBER}` : 'DEV'}</DetailText>
            </div>
            {children}
        </div>
    );
};

export {Layout};
