import {useMutation, useQuery} from '@apollo/client';
import React, {Fragment} from 'react';

import {Button, DetailText, Heading2, VStack} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {GetCompanyFeatureFlagsQuery} from '../../graphql/generated/graphql';

const GetCompanyFeatureFlags = graphql(/* GraphQL */ `
    query GetCompanyFeatureFlags($companyAccountId: ID!) {
        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: feature_flag) {
            id
            name
            description
            enabled
            reversible
        }
    }
`);

export const EnableCompanyFeatureFlag = graphql(/* GraphQL */ `
    mutation EnableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {
        enableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {
            id
        }
    }
`);

export const DisableCompanyFeatureFlag = graphql(/* GraphQL */ `
    mutation DisableCompanyFeatureFlag($companyAccountId: ID!, $featureFlag: String!) {
        disableCompanyFeatureFlag(companyAccountId: $companyAccountId, featureFlag: $featureFlag) {
            id
        }
    }
`);

type FeatureFlagData = GetCompanyFeatureFlagsQuery['getCompanyFeatureFlags'][0];

type FeatureFlagsProps = {companyAccountId: string};

export const FeatureFlags = ({companyAccountId}: FeatureFlagsProps) => {
    const {data} = useQuery(GetCompanyFeatureFlags, {variables: {companyAccountId}});
    const [enableFeatureFlag, _enableFeatureFlagResult] = useMutation(EnableCompanyFeatureFlag, {
        refetchQueries: [{query: GetCompanyFeatureFlags, variables: {companyAccountId}}],
    });
    const [disableFeatureFlag, _disableFeatureFlagResult] = useMutation(DisableCompanyFeatureFlag, {
        refetchQueries: [{query: GetCompanyFeatureFlags, variables: {companyAccountId}}],
    });
    return (
        <VStack>
            <Heading2>Feature flags</Heading2>
            <FeatureFlagTable
                flags={data?.getCompanyFeatureFlags ?? []}
                enable={(name) => {
                    enableFeatureFlag({
                        variables: {featureFlag: name, companyAccountId},
                    });
                }}
                disable={(name) => {
                    disableFeatureFlag({
                        variables: {featureFlag: name, companyAccountId},
                    });
                }}
            />
        </VStack>
    );
};

export const FeatureFlagTable: React.FC<{
    flags: FeatureFlagData[];
    enable(name: string): void;
    disable(name: string): void;
}> = ({flags, enable, disable}) => (
    <div className="grid grid-cols-6 auto-rows-max gap-2 items-center w-3/4">
        <DetailText bold className="col-start-1">
            Name
        </DetailText>
        <DetailText bold className="col-start-2 col-span-3">
            Description
        </DetailText>
        <DetailText bold className="col-start-5">
            Is flag enabled?
        </DetailText>
        <DetailText bold className="col-start-6">
            Toggle flag
        </DetailText>
        {flags.map((flag: FeatureFlagData) => (
            <FeatureFlagRow
                key={flag.id}
                data={flag}
                toggle={() => {
                    if (!flag.enabled) {
                        enable(flag.name);
                    }
                    if (flag.enabled) {
                        disable(flag.name);
                    }
                }}
            />
        ))}
    </div>
);

const FeatureFlagRow: React.FC<{
    data: FeatureFlagData;
    toggle(): void;
}> = ({data: {name, description, enabled, reversible}, toggle}) => (
    <Fragment>
        <DetailText className="col-start-1">
            {name}
            {!reversible && (
                <DetailText bold colour="error">
                    *
                </DetailText>
            )}
        </DetailText>
        <DetailText className="col-start-2 col-span-3">{description}</DetailText>
        <DetailText className="col-start-5">{enabled ? 'Yes' : 'No'}</DetailText>
        {name && (
            <Button
                className="col-start-6"
                small
                isDisabled={enabled && !reversible}
                bg={enabled ? 'alert' : undefined}
                onPress={toggle}
            >
                {enabled ? 'disable' : 'enable'}
            </Button>
        )}
    </Fragment>
);
