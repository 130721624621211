import {useMutation, useQuery} from '@apollo/client';
import React from 'react';

import {DetailText, Heading2, VStack} from '@sphericsio/design-system';

import {graphql} from '../../graphql/generated';
import {
    FeatureFlagTable,
    EnableCompanyFeatureFlag,
    DisableCompanyFeatureFlag,
} from './feature-flags';

const GetCompanySettings = graphql(/* GraphQL */ `
    query GetCompanySettings($companyAccountId: ID!) {
        getCompanyFeatureFlags(companyAccountId: $companyAccountId, type: setting) {
            id
            name
            description
            enabled
            reversible
        }
    }
`);

// Note: settings are just another view on "feature flags", where the type is "setting".
// this is because historically feature flags encompassed both settings and flags.
export const Settings = ({companyAccountId}: {companyAccountId: string}) => {
    const {data} = useQuery(GetCompanySettings, {variables: {companyAccountId}});
    const [enableSetting, _enableSettingResult] = useMutation(EnableCompanyFeatureFlag, {
        refetchQueries: [{query: GetCompanySettings, variables: {companyAccountId}}],
    });
    const [disableSetting, _disableSettingResult] = useMutation(DisableCompanyFeatureFlag, {
        refetchQueries: [{query: GetCompanySettings, variables: {companyAccountId}}],
    });
    return (
        <VStack>
            <Heading2>Settings</Heading2>
            <DetailText bold colour="error">
                Warning: settings marked with an asterisk (*) cannot be disabled once enabled.
            </DetailText>
            <FeatureFlagTable
                flags={data?.getCompanyFeatureFlags ?? []}
                enable={(name) => {
                    enableSetting({
                        variables: {featureFlag: name, companyAccountId},
                    });
                }}
                disable={(name) => {
                    disableSetting({
                        variables: {featureFlag: name, companyAccountId},
                    });
                }}
            />
        </VStack>
    );
};
