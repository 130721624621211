import {useQuery} from '@apollo/client';
import React from 'react';

import {VStack} from '@sphericsio/design-system';

import {ErrorCard} from '../../error-card';
import {Loading} from '../../loading';
import {graphql} from '../../../graphql/generated';
import {CompanyTargetingDataDetails} from './reduction-target';
import {BaselineYearReset} from './baseline-reset';
import {OperationsDataCleanup} from './operations-cleanup';

export const GetReductionTargetDocument = graphql(/* GraphQL */ `
    query GetReductionTarget($id: ID!) {
        getReductionTarget(id: $id) {
            reduction_target_type
            baseline_year
            target_completion_year
            yearly_percent_reduction
        }

        getTargetingBaseline(id: $id) {
            id
            company_reporting_period {
                period_start
                period_end
            }
        }
    }
`);

export function CompanyTargetingData({companyAccountId}: {companyAccountId: string}) {
    const {loading, error, data} = useQuery(GetReductionTargetDocument, {
        variables: {id: companyAccountId},
    });
    return (
        <VStack>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <>
                    <CompanyTargetingDataDetails
                        target={data.getReductionTarget}
                        companyAccountId={companyAccountId}
                    />
                    <BaselineYearReset
                        baseline={data.getTargetingBaseline}
                        companyAccountId={companyAccountId}
                    />
                    <OperationsDataCleanup companyAccountId={companyAccountId} />
                </>
            )}
        </VStack>
    );
}
