import React from 'react';

import {DetailText, Panel} from '@sphericsio/design-system';

type ErrorCardProps = {
    message?: React.ReactNode;
};

const ErrorCard: React.FC<ErrorCardProps> = ({message}) => {
    return (
        <Panel bg="error">
            <DetailText colour="white">{message || 'Something went wrong.'}</DetailText>
        </Panel>
    );
};

export {ErrorCard};
