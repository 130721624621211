import React from 'react';
import {useMutation} from '@apollo/client';

import {Button, VStack, Heading3, DetailText} from '@sphericsio/design-system';
import {getErrorMessage} from '@sphericsio/mvp-ui-common';

import {graphql} from '../../graphql/generated';
import {ErrorCard} from '../error-card';

const SwitchUserToAccountancyLoginDocument = graphql(/* GraphQL */ `
    mutation SwitchUserToFinancialProviderLogin($userEmailAddress: String!) {
        switchUserToFinancialProviderLogin(userEmailAddress: $userEmailAddress) {
            id
        }
    }
`);

type User = {
    id: string;
    name: string;
    email: string;
};

export function SwitchToAccountancyLogin({user, onSuccess}: {user: User; onSuccess: () => void}) {
    const [switchToAccountancyLogin, {loading, error, data}] = useMutation(
        SwitchUserToAccountancyLoginDocument,
    );

    React.useEffect(() => {
        if (data) {
            onSuccess();
        }
    }, [data]);

    return (
        <VStack align="center">
            <Heading3>Are you sure?</Heading3>
            <DetailText>
                Are you sure you want to switch the user <DetailText bold>{user.name}</DetailText>(
                {user.email}) to using the accountancy login?
            </DetailText>
            <DetailText bold colour="error">
                This cannot be reversed through the admin panel and should only be done for test
                users.
            </DetailText>

            {error && (
                <ErrorCard
                    message={getErrorMessage(error, {
                        extraErrorCodes: {
                            INVALID_FINANCIAL_PROVIDER: 'User is not a valid accountancy user',
                        },
                    })}
                />
            )}
            <Button
                bg="error"
                onPress={() =>
                    switchToAccountancyLogin({variables: {userEmailAddress: user.email}})
                }
                isLoading={loading}
            >
                Yes, switch {user.name}
            </Button>
        </VStack>
    );
}
