import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useApolloClient, useMutation} from '@apollo/client';

import {Loading} from '../components/loading';
import {graphql} from '../graphql/generated';

const LogoutDocument = graphql(/* GraphQL */ `
    mutation Logout {
        logout
    }
`);

const Logout: React.FC = () => {
    const [logout, {loading}] = useMutation(LogoutDocument);
    const navigate = useNavigate();
    const client = useApolloClient();

    React.useEffect(() => {
        logout()
            .then(() => client.resetStore())
            .then(() => navigate('/'));
    }, []);

    if (loading) {
        return <Loading />;
    }

    return null;
};

export {Logout};
