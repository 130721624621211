import {Configuration} from '@sphericsio/config';
import {LogLevelConfig} from '@sphericsio/logging';

const logging: LogLevelConfig = {
    defaultLevel: 'debug',
};

const config = {
    appName: 'admin',
    apiHost: 'http://localhost:5000',
    baseUrl: 'http://localhost:8081',
    bugsnag: {
        apiKey: 'b0c88c82c1c8242b0bd2e4595ab62efe',
        enabled: false,
    },
    logging,
};

export type Config = Configuration<typeof config>;
export default config;
