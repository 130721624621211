import React from 'react';
import {useLazyQuery, useQuery} from '@apollo/client';
import {Link} from 'react-router-dom';

import {
    SearchInput,
    FormControl,
    FormErrorMessage,
    Panel,
    DetailText,
    VStack,
    ChevronRightIcon,
    textColorClassnames,
    Button,
    Modal,
} from '@sphericsio/design-system';

import {View} from '../components/view';
import {Loading} from '../components/loading';
import {graphql} from '../graphql/generated';
import {AddCompanyForm} from '../components/add-company-form';

const GetNewCompaniesDocument = graphql(/* GraphQL */ `
    query GetNewCompanies {
        getNewCompanies {
            id
            name
        }
    }
`);

const SearchForCompanyDocument = graphql(/* GraphQL */ `
    query SearchForCompany($query: String!) {
        searchForCompany(query: $query) {
            id
            name
        }
    }
`);

type SearchEntryProps = {
    company: {id: string; name?: string | null};
};
function SearchEntry({company}: SearchEntryProps) {
    return (
        <Panel testId="company">
            <Link className="inline-block w-full" to={`/companies/detail/${company.id}`}>
                <div className="flex items-center">
                    <div className={textColorClassnames() + ' h-12'}>
                        <ChevronRightIcon />
                    </div>
                    <DetailText>{company.name}</DetailText>
                </div>
            </Link>
        </Panel>
    );
}

type AddCompanyProps = {
    onDone: () => unknown;
};
function AddCompany({onDone}: AddCompanyProps) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            <Button onPress={() => setShowModal(true)}>Add new Company</Button>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <AddCompanyForm
                    onDone={() => {
                        setShowModal(false);
                        onDone();
                    }}
                />
            </Modal>
        </>
    );
}

const SearchCompanies: React.FC = () => {
    const {loading: loadingNew, data: dataNew, refetch} = useQuery(GetNewCompaniesDocument);
    const [search, {loading, error, data}] = useLazyQuery(SearchForCompanyDocument);
    const [searchResults, setSearchResults] = React.useState(data);
    React.useEffect(() => {
        setSearchResults(data);
    }, [data, setSearchResults]);
    const onPerformSearch = React.useRef((query: string) => {
        search({variables: {query}});
    });
    return (
        <View>
            <FormControl isInvalid={error != null}>
                <SearchInput
                    name="search"
                    minCharacters={3}
                    onPerformSearch={onPerformSearch.current}
                    loading={loading}
                />
                <FormErrorMessage>Something went wrong. Try again later</FormErrorMessage>
            </FormControl>
            <VStack>
                <AddCompany onDone={() => refetch()} />
                {loadingNew && <Loading />}
                {dataNew &&
                    searchResults == null &&
                    dataNew.getNewCompanies.map((company) => (
                        <SearchEntry key={company.id} company={company} />
                    ))}
                {searchResults && (
                    <>
                        {searchResults.searchForCompany.map((company) => (
                            <SearchEntry key={company.id} company={company} />
                        ))}
                        {searchResults.searchForCompany.length === 0 && (
                            <Panel>
                                <DetailText>No companies found.</DetailText>
                            </Panel>
                        )}
                    </>
                )}
            </VStack>
        </View>
    );
};

export {SearchCompanies};
