import React from 'react';
import {useLocation, Navigate} from 'react-router-dom';
import {useMutation} from '@apollo/client';

import {GetCurrentUserDocument} from '../components/user-context';
import {ErrorCard} from './error-card';
import {Loading} from './loading';
import {graphql} from '../graphql/generated';

export const IdentityProviderCallbackDocument = graphql(/* GraphQL */ `
    mutation IdentityProviderCallback($params: String!, $provider: IdentityProviderName!) {
        identityProviderCallback(params: $params, provider: $provider) {
            id
        }
    }
`);

const IDENTITY_PROVIDER = 'sageid';
export function IdentityProviderCallback() {
    const location = useLocation();
    const [preMutation, setPreMutation] = React.useState(true);
    const [providerCallback, {loading, error}] = useMutation(IdentityProviderCallbackDocument);

    React.useLayoutEffect(() => {
        providerCallback({
            variables: {
                params: `${location.pathname}${location.search}`,
                provider: IDENTITY_PROVIDER,
            },
            refetchQueries: [{query: GetCurrentUserDocument}],
            awaitRefetchQueries: true,
        });
        setPreMutation(false);
    }, []);

    if (loading || preMutation) {
        return <Loading />;
    }

    if (error) {
        return <ErrorCard message="Something went wrong." />;
    }

    return <Navigate to="/" />;
}
