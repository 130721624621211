import React from 'react';
import {LinkProps as RouterLinkProps, useHref, useNavigate} from 'react-router-dom';

import {
    Link as DesignSystemLink,
    ButtonLink as DesignSystemButtonLink,
    ButtonProps,
    LinkProps,
    PressEvent,
} from '@sphericsio/design-system';

type ToProp = Pick<RouterLinkProps, 'to' | 'state'> & Pick<ButtonProps, 'onPress'>;
function useRouterLink(props: ToProp) {
    const href = useHref(props.to);
    const navigate = useNavigate();
    return {
        href,
        onPress: (e: PressEvent) => {
            if (props.onPress) {
                props.onPress(e);
            }
            navigate(href, {state: props.state});
        },
    };
}
const Link: React.FC<ToProp & Pick<LinkProps, 'active' | 'children'>> = (props) => {
    const routerLinkProps = useRouterLink(props);
    return (
        <DesignSystemLink {...routerLinkProps} active={props.active}>
            {props.children}
        </DesignSystemLink>
    );
};

const ButtonLink: React.FC<ToProp & ButtonProps> = (props) => {
    const routerLinkProps = useRouterLink(props);
    return <DesignSystemButtonLink {...routerLinkProps}>{props.children}</DesignSystemButtonLink>;
};

export {Link, ButtonLink};
