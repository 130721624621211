import React from 'react';
import {useLazyQuery, useMutation} from '@apollo/client';
import {isEmpty} from 'lodash';

import {Button, Heading2, HStack, VStack} from '@sphericsio/design-system';

import {AlertIcon} from '../../icons';
import {graphql} from '../../../graphql/generated';

const removeOverlappingBaselineOps = graphql(/* GraphQL */ `
    mutation RemoveOverlappingBaselineOpsProfile($id: ID!) {
        removeOverlappingBaselineOpsProfile(id: $id)
    }
`);

const getOverlappingBaselineOps = graphql(/* GraphQL */ `
    query GetOverlappingBaselineOpsProfile($id: ID!) {
        getOverlappingOpsBaselineProfile(id: $id) {
            baseline_profile_id
            baseline_period_start
            baseline_period_end
            ops_profile_id
            ops_period_start
            ops_period_end
            ops_reporting_period_id
            profile_type
        }
    }
`);

type OverlappingDataProps = {
    companyAccountId: string;
};
export const OperationsDataCleanup = ({companyAccountId}: OverlappingDataProps) => {
    const [getOverlaps, {data, loading: getOverlapsLoading, error}] = useLazyQuery(
        getOverlappingBaselineOps,
        {
            variables: {id: companyAccountId},
        },
    );
    const [reset, {loading: removeOverlapsLoading}] = useMutation(removeOverlappingBaselineOps, {
        variables: {id: companyAccountId},
        refetchQueries: [getOverlappingBaselineOps],
    });
    const onPress = React.useCallback(async () => {
        const ok = confirm('Are you sure? This is unrecoverable.');
        if (ok) {
            await reset();
        }
    }, [reset, companyAccountId]);

    return (
        <VStack>
            <Heading2>
                Operations data cleanup - removes operations data for periods where it overlaps with
                targeting baseline data
            </Heading2>

            <HStack>
                <Button
                    icon={<AlertIcon />}
                    isLoading={getOverlapsLoading}
                    bg="info"
                    onPress={() => getOverlaps()}
                >
                    Get overlapping data
                </Button>
            </HStack>
            <HStack>
                {getOverlapsLoading && 'Loading'}
                {error?.message}
                {data && !isEmpty(data.getOverlappingOpsBaselineProfile) && (
                    <div className="flex flex-col flex-1 space-top1">
                        <Button
                            icon={<AlertIcon />}
                            isLoading={removeOverlapsLoading}
                            bg="error"
                            onPress={onPress}
                        >
                            Clean up operations data
                        </Button>
                        {data.getOverlappingOpsBaselineProfile.map(
                            ({
                                baseline_profile_id,
                                baseline_period_start,
                                baseline_period_end,
                                ops_profile_id,
                                ops_period_start,
                                ops_period_end,
                                profile_type,
                            }) => (
                                <div
                                    key={ops_profile_id}
                                    className="grid grid-flow-col-dense grid-cols-6"
                                >
                                    <div>Baseline profile: {profile_type}</div>
                                    <div>{baseline_profile_id}</div>
                                    <div>
                                        {baseline_period_start} to {baseline_period_end}
                                    </div>
                                    <div>overlaps with operations profile</div>
                                    <div>{ops_profile_id}</div>
                                    <div>
                                        {ops_period_start} to {ops_period_end}
                                    </div>
                                </div>
                            ),
                        )}
                    </div>
                )}
            </HStack>
        </VStack>
    );
};
