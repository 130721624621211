import React from 'react';
import classnames from 'classnames';

import {DetailText, textColorClassnames} from '@sphericsio/design-system';
import {Elements, TableTextAlignment} from '@sphericsio/mvp-ui-common';

const defaultTableProps = {striped: true};
const TablePropsContext = React.createContext(defaultTableProps);

function textAlignClassnames(textAlign: TableTextAlignment) {
    return classnames({
        'text-left': textAlign === 'left',
        'text-center': textAlign === 'center',
        'text-right': textAlign === 'right',
    });
}

const Table: Elements['Table'] = (props) => {
    const tableProps = {
        striped: true,
    };
    return (
        <TablePropsContext.Provider value={tableProps}>
            <table className="w-full rounded-lg overflow-hidden" {...props} />
        </TablePropsContext.Provider>
    );
};

const THeadContext = React.createContext(false);
const THead: Elements['THead'] = (props) => {
    return (
        <THeadContext.Provider value={true}>
            <thead className="border-b bg-solid" {...props} />
        </THeadContext.Provider>
    );
};

const TRow: Elements['TRow'] = (props) => {
    const inTHead = React.useContext(THeadContext);

    return (
        <tr
            className={classnames({
                'bg-white': !inTHead,
            })}
            {...props}
        />
    );
};

const THeader: Elements['THeader'] = ({children, textAlign = 'left', width, ...props}) => {
    return (
        <th
            className={classnames(
                'p-2 font-heading',
                textColorClassnames('default'),
                textAlignClassnames(textAlign),
            )}
            style={{width}}
            {...props}
        >
            {children}
        </th>
    );
};

const TBody: Elements['TBody'] = (props) => {
    return <tbody {...props} />;
};

const TData: Elements['TData'] = ({children, textAlign = 'left', className, ...props}) => {
    return (
        <td className={classnames('p-2', className, textAlignClassnames(textAlign))} {...props}>
            <DetailText inline={false}>{children}</DetailText>
        </td>
    );
};

export {Table, THead, TRow, THeader, TBody, TData};
