import React from 'react';
import {BrowserRouter, Route, Routes as RouterRoutes} from 'react-router-dom';

import {Layout} from './components/layout';
import {LoggedInRoutes} from './components/logged-in-routes';
import {Index} from './views/index';
import {Login} from './views/login';
import {Logout} from './views/logout';
import {SearchCompanies} from './views/companies';
import {CompanyDetail} from './views/company-detail';
import {FinancialProviders} from './views/financial-providers';
import {Users} from './views/users';
import {IdentityProviderCallback} from './components/callback';

const Routes: React.FC = () => {
    return (
        <BrowserRouter>
            <Layout>
                <RouterRoutes>
                    <LoggedInRoutes>
                        <Route path="/" element={<Index />} />
                        <Route path="/logout" element={<Logout />} />
                        <Route path="/companies" element={<SearchCompanies />} />
                        <Route path="/companies/detail/:companyId" element={<CompanyDetail />} />
                        <Route path="/financial-providers" element={<FinancialProviders />} />
                        <Route path="/users" element={<Users />} />
                    </LoggedInRoutes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/sage-id/callback" element={<IdentityProviderCallback />} />
                </RouterRoutes>
            </Layout>
        </BrowserRouter>
    );
};

export {Routes};
