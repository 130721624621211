/* eslint-disable */
import type { Money } from '../scalars';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import type { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: string; output: string; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: string; output: string; }
  Money: { input: Money; output: Money; }
  PlainDate: { input: any; output: any; }
  TimeseriesPoints: { input: any; output: any; }
  ZonedDateTime: { input: any; output: any; }
};

export type AddCompanyInput = {
  companyName: Scalars['String']['input'];
  email: Scalars['String']['input'];
  isFreeForLife: Scalars['Boolean']['input'];
  isSage50User: Scalars['Boolean']['input'];
  isSagePilotUser: Scalars['Boolean']['input'];
  name: Scalars['String']['input'];
};

export type AddMatcher = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  matcher: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type AddMerchantCarbonIntensityParams = {
  merchantId: Scalars['ID']['input'];
  sourceUrl: Scalars['String']['input'];
  sourceYear: Scalars['Int']['input'];
  value: Scalars['Float']['input'];
};

export type AddUserInput = {
  companyAccountId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  roles: Array<Scalars['String']['input']>;
};

export type AdminCompany = {
  __typename?: 'AdminCompany';
  email: Scalars['String']['output'];
  financialDataQuality: FinancialDataQuality;
  financialProviderSyncStatus: Array<FinancialProviderSync>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  subscription?: Maybe<AdminSubscription>;
};

export type AdminMerchant = MerchantBase & {
  __typename?: 'AdminMerchant';
  aliases?: Maybe<Array<MerchantAlias>>;
  carbonIntensities: Array<CarbonIntensity>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identified: Scalars['Boolean']['output'];
  matchers: Array<Matcher>;
  metadata: Array<MerchantMetadata>;
  name: Scalars['String']['output'];
  sicCodes: Array<SicCode>;
  taxonomy: MerchantTaxonomy;
  transactions: AdminTransactionConnection;
};


export type AdminMerchantTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: TransactionOrder;
  orderDirection: SortDirection;
};

export type AdminMerchantConnection = {
  __typename?: 'AdminMerchantConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<AdminMerchantEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type AdminMerchantEdge = {
  __typename?: 'AdminMerchantEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: AdminMerchant;
};

export type AdminSubscription = {
  __typename?: 'AdminSubscription';
  id: Scalars['ID']['output'];
  isTrial: Scalars['Boolean']['output'];
  status: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
  trialPeriodEnds?: Maybe<Scalars['DateTime']['output']>;
};

export type AdminTransaction = TransactionBase & {
  __typename?: 'AdminTransaction';
  amount: Scalars['Money']['output'];
  description: Scalars['String']['output'];
  financial_account: FinancialAccount;
  id: Scalars['ID']['output'];
  merchant?: Maybe<Merchant>;
  merchant_id?: Maybe<Scalars['ID']['output']>;
  transaction_date: Scalars['DateTime']['output'];
};

export type AdminTransactionConnection = {
  __typename?: 'AdminTransactionConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<AdminTransactionEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type AdminTransactionEdge = {
  __typename?: 'AdminTransactionEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: AdminTransaction;
};

export type AuthorisationUrl = {
  __typename?: 'AuthorisationUrl';
  url: Scalars['String']['output'];
};

export type BackgroundExecution = {
  __typename?: 'BackgroundExecution';
  completed_at?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  execution_type: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  status: Scalars['String']['output'];
  updated_at: Scalars['DateTime']['output'];
  upload_errors?: Maybe<Array<UploadValidationErrors>>;
  user_account_id?: Maybe<Scalars['String']['output']>;
};

export type CarbonIntensity = {
  __typename?: 'CarbonIntensity';
  carbon_intensity: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  reference_url: Scalars['String']['output'];
  year: Scalars['Int']['output'];
};

export type CompaniesHouseSearchResult = {
  __typename?: 'CompaniesHouseSearchResult';
  company_number: Scalars['ID']['output'];
  company_status?: Maybe<Scalars['String']['output']>;
  company_type: Scalars['String']['output'];
  date_of_cessation?: Maybe<Scalars['String']['output']>;
  date_of_creation?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CompanyReportingPeriod = {
  __typename?: 'CompanyReportingPeriod';
  company_account_id: Scalars['ID']['output'];
  energy?: Maybe<ProfileEntry>;
  ghg?: Maybe<ProfileEntry>;
  id: Scalars['ID']['output'];
  isBaselineReportingPeriod: Scalars['Boolean']['output'];
  isCompleted: Scalars['Boolean']['output'];
  isOpen: Scalars['Boolean']['output'];
  people?: Maybe<ProfileEntry>;
  period_end: Scalars['Date']['output'];
  period_start: Scalars['Date']['output'];
  totalEmissionsForPeriod: Scalars['Float']['output'];
  vehicles?: Maybe<ProfileEntry>;
};

export type CompanySubscription = FreeForLifeCompanySubscription | RecurringCompanySubscription;

export type EmissionsRefreshTarget =
  | 'all'
  | 'operations'
  | 'transactions';

export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  description: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  reversible: Scalars['Boolean']['output'];
  type: FeatureFlagType;
};

export type FeatureFlagType =
  | 'feature_flag'
  | 'setting';

export type FileRequestDetail = {
  __typename?: 'FileRequestDetail';
  file_extension: Scalars['String']['output'];
  filename: Scalars['String']['output'];
  id: Scalars['String']['output'];
  upload_request_type?: Maybe<Scalars['String']['output']>;
};

export type FinancialAccount = {
  __typename?: 'FinancialAccount';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  terms: Array<TaxonomyTerm>;
};

export type FinancialDataQuality = {
  __typename?: 'FinancialDataQuality';
  status: FinancialDataQualityStatus;
  totalMerchants: Scalars['Int']['output'];
  totalTransactions: Scalars['Int']['output'];
  transactionsWithoutMerchants: Array<Transaction>;
  unidentifiedMerchants: Array<Merchant>;
};

export type FinancialDataQualityStatus =
  | 'AllIdentified'
  | 'AllNeedsIdentification'
  | 'NoData'
  | 'SomeNeedsIdentification';

export type FinancialProvider = {
  __typename?: 'FinancialProvider';
  connection_status: FinancialProviderConnectionStatus;
  financial_provider: Scalars['String']['output'];
  sync_method: Scalars['String']['output'];
  sync_status: ProviderSyncStatus;
};

export type FinancialProviderConnectionStatus =
  | 'missing_tenant'
  | 'missing_token'
  | 'ready';

export type FinancialProviderSync = {
  __typename?: 'FinancialProviderSync';
  completed_at?: Maybe<Scalars['DateTime']['output']>;
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sync_method: Scalars['String']['output'];
  sync_status: Scalars['String']['output'];
  sync_type: Scalars['String']['output'];
};

export type FinancialTransactionSync = {
  __typename?: 'FinancialTransactionSync';
  company_account: AdminCompany;
  company_account_id: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  sync_method: Scalars['String']['output'];
  sync_type: Scalars['String']['output'];
};

export type FreeForLifeCompanySubscription = {
  __typename?: 'FreeForLifeCompanySubscription';
  status: SubscriptionStatus;
};

export type IdentityProviderName =
  | 'fake'
  | 'quickbooks'
  | 'sage'
  | 'sageid'
  | 'xero';

export type InviteResponse = {
  __typename?: 'InviteResponse';
  code: Scalars['String']['output'];
  expires_at: Scalars['ZonedDateTime']['output'];
};

export type LastSync = {
  __typename?: 'LastSync';
  completed_at?: Maybe<Scalars['DateTime']['output']>;
  sync_type: Scalars['String']['output'];
};

export type Matcher = {
  __typename?: 'Matcher';
  enabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  query: Scalars['String']['output'];
};

export type Merchant = MerchantBase & {
  __typename?: 'Merchant';
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  transactions: TransactionConnection;
};


export type MerchantTransactionsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: TransactionOrder;
  orderDirection: SortDirection;
};

export type MerchantAlias = {
  __typename?: 'MerchantAlias';
  id: Scalars['ID']['output'];
  name_alias: Scalars['String']['output'];
};

export type MerchantBase = {
  created_at: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  identified: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};

export type MerchantMetadata = {
  __typename?: 'MerchantMetadata';
  id: Scalars['ID']['output'];
  metadata_type: Scalars['String']['output'];
  metadata_value?: Maybe<Scalars['String']['output']>;
};

export type MerchantMetadataType =
  | 'airline'
  | 'ghg_supplier'
  | 'staff_member'
  | 'vehicle_fuel_supplier';

export type MerchantTaxonomy = {
  __typename?: 'MerchantTaxonomy';
  overrideTaxonomyTerm?: Maybe<Scalars['String']['output']>;
  suggestedTaxonomyTerm?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addCompany: AdminCompany;
  addMatcherToMerchant: AdminMerchant;
  addMerchantCarbonIntensity: AdminMerchant;
  addUser: User;
  cancelCompanySubscription: AdminCompany;
  cancelSubscription: User;
  clearStuckSync: Array<FinancialTransactionSync>;
  createMerchantFromTransaction: AdminMerchant;
  createSubscription: SubscriptionCreationResponse;
  disableCompanyFeatureFlag: Array<FeatureFlag>;
  disableUser: User;
  disconnectCompanyFinancialProvider: Array<FinancialProviderSync>;
  editMerchantTaxonomyTerms: AdminMerchant;
  enableCompanyFeatureFlag: Array<FeatureFlag>;
  extendSubscriptionTrial: AdminSubscription;
  forceCompleteFinancialProviderResync?: Maybe<BackgroundExecution>;
  getIdentityProviderUrl?: Maybe<AuthorisationUrl>;
  identityProviderCallback?: Maybe<User>;
  logout: Scalars['Boolean']['output'];
  mergeMerchant: AdminMerchant;
  refreshCompanyTransactions: AdminCompany;
  removeMerchantCarbonIntensity: AdminMerchant;
  removeOverlappingBaselineOpsProfile: Scalars['Boolean']['output'];
  resendCompanyUserInvite: InviteResponse;
  resetCompanyData: AdminCompany;
  resetReductionTarget: AdminCompany;
  resetTargetingBaseline: Scalars['String']['output'];
  setCompaniesHouseIdForMerchant: AdminMerchant;
  setMerchantMetadataItem: AdminMerchant;
  setMerchantSicCodes: AdminMerchant;
  switchUserToFinancialProviderLogin: User;
  updateDefaultSubscriptionPaymentMethod: User;
  updateMatcherForMerchant: AdminMerchant;
  updateTermsAndConditionsApproval?: Maybe<User>;
};


export type MutationAddCompanyArgs = {
  addCompanyInput: AddCompanyInput;
};


export type MutationAddMatcherToMerchantArgs = {
  matcher: AddMatcher;
  merchantId: Scalars['ID']['input'];
};


export type MutationAddMerchantCarbonIntensityArgs = {
  params: AddMerchantCarbonIntensityParams;
};


export type MutationAddUserArgs = {
  addUserInput: AddUserInput;
};


export type MutationCancelCompanySubscriptionArgs = {
  companyAccountId: Scalars['ID']['input'];
};


export type MutationClearStuckSyncArgs = {
  id: Scalars['ID']['input'];
};


export type MutationCreateMerchantFromTransactionArgs = {
  name: Scalars['String']['input'];
  transactionId: Scalars['ID']['input'];
};


export type MutationCreateSubscriptionArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  priceId: Scalars['String']['input'];
  stripePaymentMethodId: Scalars['String']['input'];
};


export type MutationDisableCompanyFeatureFlagArgs = {
  companyAccountId: Scalars['ID']['input'];
  featureFlag: Scalars['String']['input'];
};


export type MutationDisableUserArgs = {
  userAccountId: Scalars['ID']['input'];
};


export type MutationDisconnectCompanyFinancialProviderArgs = {
  companyAccountId: Scalars['String']['input'];
};


export type MutationEditMerchantTaxonomyTermsArgs = {
  merchantId: Scalars['ID']['input'];
  overrideTaxonomyTerm?: InputMaybe<Scalars['String']['input']>;
  suggestedTaxonomyTerm?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEnableCompanyFeatureFlagArgs = {
  companyAccountId: Scalars['ID']['input'];
  featureFlag: Scalars['String']['input'];
};


export type MutationExtendSubscriptionTrialArgs = {
  companyAccountId: Scalars['ID']['input'];
  trialEnd: Scalars['DateTime']['input'];
};


export type MutationForceCompleteFinancialProviderResyncArgs = {
  companyAccountId: Scalars['ID']['input'];
  financialProvider: Scalars['String']['input'];
};


export type MutationIdentityProviderCallbackArgs = {
  params: Scalars['String']['input'];
  provider: IdentityProviderName;
};


export type MutationMergeMerchantArgs = {
  id: Scalars['ID']['input'];
  mergeWithMerchantId: Scalars['ID']['input'];
};


export type MutationRefreshCompanyTransactionsArgs = {
  id: Scalars['ID']['input'];
  target: EmissionsRefreshTarget;
};


export type MutationRemoveMerchantCarbonIntensityArgs = {
  id: Scalars['ID']['input'];
  merchantId: Scalars['ID']['input'];
};


export type MutationRemoveOverlappingBaselineOpsProfileArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResendCompanyUserInviteArgs = {
  companyAccountId: Scalars['ID']['input'];
  userEmail: Scalars['String']['input'];
};


export type MutationResetCompanyDataArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetReductionTargetArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetTargetingBaselineArgs = {
  baselineId: Scalars['ID']['input'];
};


export type MutationSetCompaniesHouseIdForMerchantArgs = {
  companyNumber: Scalars['String']['input'];
  merchantId: Scalars['ID']['input'];
};


export type MutationSetMerchantMetadataItemArgs = {
  params: SetMerchantMetadata;
};


export type MutationSetMerchantSicCodesArgs = {
  merchantId: Scalars['ID']['input'];
  sicCodes: Array<Scalars['String']['input']>;
};


export type MutationSwitchUserToFinancialProviderLoginArgs = {
  userEmailAddress: Scalars['String']['input'];
};


export type MutationUpdateDefaultSubscriptionPaymentMethodArgs = {
  stripePaymentMethodId: Scalars['String']['input'];
};


export type MutationUpdateMatcherForMerchantArgs = {
  params: UpdateMatcher;
};


export type MutationUpdateTermsAndConditionsApprovalArgs = {
  approve: Scalars['Boolean']['input'];
};

export type OverlappingBaseline = {
  __typename?: 'OverlappingBaseline';
  baseline_period_end: Scalars['Date']['output'];
  baseline_period_start: Scalars['Date']['output'];
  baseline_profile_id: Scalars['String']['output'];
  company_account_id: Scalars['String']['output'];
  ops_period_end: Scalars['Date']['output'];
  ops_period_start: Scalars['Date']['output'];
  ops_profile_id: Scalars['String']['output'];
  ops_reporting_period_id: Scalars['String']['output'];
  profile_type: Scalars['String']['output'];
};

/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean']['output'];
  /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

export type PaymentStatus =
  | 'cancelled'
  | 'payment_failed'
  | 'payment_received'
  | 'pending_payment';

export type ProfileBenchmark = {
  __typename?: 'ProfileBenchmark';
  benchmarkKey: Scalars['String']['output'];
  benchmarkText: Scalars['String']['output'];
  benchmarkTextUnit: Scalars['String']['output'];
  reference: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type ProfileEntry = {
  __typename?: 'ProfileEntry';
  benchmark?: Maybe<ProfileBenchmark>;
  completed_at: Scalars['DateTime']['output'];
  entry_type: ProfileEntryType;
  id: Scalars['ID']['output'];
  kgCo2e: Scalars['Float']['output'];
};

export type ProfileEntryType =
  | 'energy'
  | 'ghg'
  | 'people'
  | 'vehicles';

export type ProviderFinancialAccount = {
  __typename?: 'ProviderFinancialAccount';
  description: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ProviderSyncStatus =
  | 'InitialSyncInProgress'
  | 'PreInitialSync'
  | 'ProviderSyncError'
  | 'Successful';

export type Query = {
  __typename?: 'Query';
  currentUser?: Maybe<User>;
  fetchBackgroundExecution?: Maybe<BackgroundExecution>;
  fetchLastBackgroundExecution?: Maybe<BackgroundExecution>;
  getAccount: ProviderFinancialAccount;
  getAdminMerchantById: AdminMerchant;
  getAdminUsers: Array<User>;
  getAllTaxonomies: Array<Taxonomy>;
  getAllUnidentifiedMerchants: AdminMerchantConnection;
  getCompany: AdminCompany;
  getCompanyFeatureFlags: Array<FeatureFlag>;
  getCompanyUsers: Array<User>;
  getNewCompanies: Array<AdminCompany>;
  getOverlappingOpsBaselineProfile: Array<OverlappingBaseline>;
  getReductionTarget?: Maybe<ReductionTarget>;
  getSIBSEBuildingTypes: Array<SibseBuildingType>;
  getSicCodes: Array<SicCode>;
  getStuckSyncs: Array<FinancialTransactionSync>;
  getSubscriptionAuthenticationParams?: Maybe<SubscriptionAuthenticationParams>;
  getSubscriptionPromotionalCode: SubscriptionPromotionalCode;
  getSubscriptionTrialPeriodDays: Scalars['Int']['output'];
  getTargetingBaseline?: Maybe<TargetingBaseline>;
  getTransactionsWithoutMerchants: AdminTransactionConnection;
  listSubscriptionProducts: Array<SubscriptionProduct>;
  searchCompaniesHouse: Array<CompaniesHouseSearchResult>;
  searchForCompany: Array<AdminCompany>;
  searchForMerchant: Array<Merchant>;
};


export type QueryFetchBackgroundExecutionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryFetchLastBackgroundExecutionArgs = {
  type?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryGetAccountArgs = {
  companyAccountId: Scalars['String']['input'];
  financialAccountId: Scalars['String']['input'];
};


export type QueryGetAdminMerchantByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetAllUnidentifiedMerchantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy: UnidentifiedMerchantsOrder;
  orderDirection: SortDirection;
};


export type QueryGetCompanyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCompanyFeatureFlagsArgs = {
  companyAccountId: Scalars['ID']['input'];
  type?: InputMaybe<FeatureFlagType>;
};


export type QueryGetCompanyUsersArgs = {
  companyAccountId: Scalars['ID']['input'];
};


export type QueryGetOverlappingOpsBaselineProfileArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetReductionTargetArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSubscriptionPromotionalCodeArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetTargetingBaselineArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetTransactionsWithoutMerchantsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: TransactionOrder;
  orderDirection?: SortDirection;
};


export type QuerySearchCompaniesHouseArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchForCompanyArgs = {
  query: Scalars['String']['input'];
};


export type QuerySearchForMerchantArgs = {
  query: Scalars['String']['input'];
};

export type RecurringCompanySubscription = {
  __typename?: 'RecurringCompanySubscription';
  cancellation_period_over?: Maybe<Scalars['Boolean']['output']>;
  expires_at?: Maybe<Scalars['DateTime']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  in_trial_period: Scalars['Boolean']['output'];
  payment_status: PaymentStatus;
  status: SubscriptionStatus;
  updated_at: Scalars['DateTime']['output'];
};

export type ReductionTarget = {
  __typename?: 'ReductionTarget';
  baseline_year: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  reduction_target_type: Scalars['String']['output'];
  target_completion_year: Scalars['Int']['output'];
  yearly_percent_reduction: Scalars['Float']['output'];
};

export type SibseBuildingType = {
  __typename?: 'SIBSEBuildingType';
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
};

export type ScopeCo2eBreakdown = {
  __typename?: 'ScopeCo2eBreakdown';
  scope_1_kg_co2e: Scalars['Float']['output'];
  scope_2_kg_co2e: Scalars['Float']['output'];
  scope_3_kg_co2e: Scalars['Float']['output'];
  total_kg_co2e: Scalars['Float']['output'];
};

export type SetMerchantMetadata = {
  id?: InputMaybe<Scalars['ID']['input']>;
  merchant_id: Scalars['ID']['input'];
  metadata_type?: InputMaybe<MerchantMetadataType>;
  metadata_value?: InputMaybe<Scalars['String']['input']>;
  remove?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SicCode = {
  __typename?: 'SicCode';
  description: Scalars['String']['output'];
  sic_code: Scalars['String']['output'];
};

export type SortDirection =
  | 'asc'
  | 'desc';

export type SubscriptionAuthenticationParams = {
  __typename?: 'SubscriptionAuthenticationParams';
  client_secret?: Maybe<Scalars['String']['output']>;
  failure_reason?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionCreationResponse = {
  __typename?: 'SubscriptionCreationResponse';
  clientSecret: Scalars['String']['output'];
  type: SubscriptionCreationResponseType;
};

export type SubscriptionCreationResponseType =
  | 'PaymentIntent'
  | 'SetupIntent';

export type SubscriptionProduct = {
  __typename?: 'SubscriptionProduct';
  amount: Scalars['Money']['output'];
  name: Scalars['String']['output'];
  priceId: Scalars['String']['output'];
  productId: Scalars['String']['output'];
  recurringPeriod?: Maybe<Scalars['String']['output']>;
  recurringPeriodAmount?: Maybe<Scalars['Int']['output']>;
};

export type SubscriptionPromotionalCode = {
  __typename?: 'SubscriptionPromotionalCode';
  amount_off?: Maybe<Scalars['Money']['output']>;
  applies_to_products?: Maybe<Array<Scalars['String']['output']>>;
  duration: Scalars['String']['output'];
  duration_in_months?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
  percent_off?: Maybe<Scalars['Float']['output']>;
};

export type SubscriptionStatus =
  | 'active'
  | 'cancelled'
  | 'error'
  | 'expired'
  | 'pending';

export type TargetingBaseline = {
  __typename?: 'TargetingBaseline';
  company_reporting_period: CompanyReportingPeriod;
  company_reporting_period_id: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  scope_co2e_breakdown?: Maybe<ScopeCo2eBreakdown>;
};

export type Taxonomy = {
  __typename?: 'Taxonomy';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  terms: Array<TaxonomyTerm>;
};

export type TaxonomyTerm = {
  __typename?: 'TaxonomyTerm';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  taxonomyKey: Scalars['ID']['output'];
};

export type Transaction = TransactionBase & {
  __typename?: 'Transaction';
  amount: Scalars['Money']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  merchant?: Maybe<Merchant>;
  merchant_id?: Maybe<Scalars['ID']['output']>;
  transaction_date: Scalars['DateTime']['output'];
};

export type TransactionBase = {
  amount: Scalars['Money']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  merchant?: Maybe<Merchant>;
  merchant_id?: Maybe<Scalars['ID']['output']>;
  transaction_date: Scalars['DateTime']['output'];
};

export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
  edges: Array<TransactionEdge>;
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
  pageInfo: PageInfo;
};

export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
  cursor: Scalars['String']['output'];
  /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
  node: Transaction;
};

export type TransactionOrder =
  | 'amount'
  | 'description'
  | 'id'
  | 'transaction_date';

export type UnidentifiedMerchantsOrder =
  | 'id'
  | 'name';

export type UpdateMatcher = {
  enabled?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['ID']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
};

export type UploadValidationErrors = {
  __typename?: 'UploadValidationErrors';
  code: Scalars['String']['output'];
  file_upload_request: FileRequestDetail;
  header?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  line: Scalars['Int']['output'];
  message: Scalars['String']['output'];
  sheetName?: Maybe<Scalars['String']['output']>;
  sheetNo?: Maybe<Scalars['Int']['output']>;
};

export type User = {
  __typename?: 'User';
  company_account_id: Scalars['String']['output'];
  company_name: Scalars['String']['output'];
  created_at: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  enabled: Scalars['Boolean']['output'];
  feature_flags: Array<Scalars['String']['output']>;
  financialProvider?: Maybe<FinancialProvider>;
  hasTransactions: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  intercom_hash: Scalars['String']['output'];
  isCompanyOnboarded: Scalars['Boolean']['output'];
  isEmailVerified: Scalars['Boolean']['output'];
  isLeadCompanyUser: Scalars['Boolean']['output'];
  lastSync?: Maybe<LastSync>;
  name: Scalars['String']['output'];
  roles: Array<Scalars['String']['output']>;
  subscription?: Maybe<CompanySubscription>;
  terms_and_conditions_accepted: Scalars['Boolean']['output'];
};

export type AddCompanyMutationVariables = Exact<{
  company: AddCompanyInput;
}>;


export type AddCompanyMutation = { __typename?: 'Mutation', addCompany: { __typename?: 'AdminCompany', id: string } };

export type IdentityProviderCallbackMutationVariables = Exact<{
  params: Scalars['String']['input'];
  provider: IdentityProviderName;
}>;


export type IdentityProviderCallbackMutation = { __typename?: 'Mutation', identityProviderCallback?: { __typename?: 'User', id: string } | null };

export type GetCompanyAccountingDataQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCompanyAccountingDataQuery = { __typename?: 'Query', getCompany: { __typename?: 'AdminCompany', id: string, financialDataQuality: { __typename?: 'FinancialDataQuality', totalTransactions: number, totalMerchants: number, transactionsWithoutMerchants: Array<{ __typename?: 'Transaction', id: string, description: string, transaction_date: string, amount: Money }> }, financialProviderSyncStatus: Array<{ __typename?: 'FinancialProviderSync', id: string, created_at: string, completed_at?: string | null, sync_type: string, sync_method: string, sync_status: string }> } };

export type ForceCompleteProviderResyncMutationVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
  financialProvider: Scalars['String']['input'];
}>;


export type ForceCompleteProviderResyncMutation = { __typename?: 'Mutation', forceCompleteFinancialProviderResync?: { __typename?: 'BackgroundExecution', id: string } | null };

export type ResetCompanyDataMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ResetCompanyDataMutation = { __typename?: 'Mutation', resetCompanyData: { __typename?: 'AdminCompany', id: string } };

export type DisconnectCompanyFinancialProviderMutationVariables = Exact<{
  companyAccountId: Scalars['String']['input'];
}>;


export type DisconnectCompanyFinancialProviderMutation = { __typename?: 'Mutation', disconnectCompanyFinancialProvider: Array<{ __typename?: 'FinancialProviderSync', id: string, sync_type: string, sync_status: string, created_at: string }> };

export type RefreshCompanyTransactionDataMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  target: EmissionsRefreshTarget;
}>;


export type RefreshCompanyTransactionDataMutation = { __typename?: 'Mutation', refreshCompanyTransactions: { __typename?: 'AdminCompany', id: string } };

export type AddUserMutationVariables = Exact<{
  user: AddUserInput;
}>;


export type AddUserMutation = { __typename?: 'Mutation', addUser: { __typename?: 'User', id: string } };

export type GetAccountQueryVariables = Exact<{
  companyAccountId: Scalars['String']['input'];
  financialAccountId: Scalars['String']['input'];
}>;


export type GetAccountQuery = { __typename?: 'Query', getAccount: { __typename?: 'ProviderFinancialAccount', id: string, name: string, description: string } };

export type DisableUserMutationVariables = Exact<{
  user: Scalars['ID']['input'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUser: { __typename?: 'User', id: string } };

export type GetCompanyFeatureFlagsQueryVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
}>;


export type GetCompanyFeatureFlagsQuery = { __typename?: 'Query', getCompanyFeatureFlags: Array<{ __typename?: 'FeatureFlag', id: string, name: string, description: string, enabled: boolean, reversible: boolean }> };

export type EnableCompanyFeatureFlagMutationVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
  featureFlag: Scalars['String']['input'];
}>;


export type EnableCompanyFeatureFlagMutation = { __typename?: 'Mutation', enableCompanyFeatureFlag: Array<{ __typename?: 'FeatureFlag', id: string }> };

export type DisableCompanyFeatureFlagMutationVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
  featureFlag: Scalars['String']['input'];
}>;


export type DisableCompanyFeatureFlagMutation = { __typename?: 'Mutation', disableCompanyFeatureFlag: Array<{ __typename?: 'FeatureFlag', id: string }> };

export type GetCompanySettingsQueryVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
}>;


export type GetCompanySettingsQuery = { __typename?: 'Query', getCompanyFeatureFlags: Array<{ __typename?: 'FeatureFlag', id: string, name: string, description: string, enabled: boolean, reversible: boolean }> };

export type GetCompanySubscriptionQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCompanySubscriptionQuery = { __typename?: 'Query', getCompany: { __typename?: 'AdminCompany', id: string, subscription?: { __typename?: 'AdminSubscription', stripeSubscriptionId: string, isTrial: boolean, trialPeriodEnds?: string | null, status: string } | null } };

export type ExtendSubscriptionTrialMutationVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
  trialEnd: Scalars['DateTime']['input'];
}>;


export type ExtendSubscriptionTrialMutation = { __typename?: 'Mutation', extendSubscriptionTrial: { __typename?: 'AdminSubscription', stripeSubscriptionId: string, isTrial: boolean, trialPeriodEnds?: string | null } };

export type CancelCompanySubscriptionMutationVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
}>;


export type CancelCompanySubscriptionMutation = { __typename?: 'Mutation', cancelCompanySubscription: { __typename?: 'AdminCompany', id: string } };

export type SwitchUserToFinancialProviderLoginMutationVariables = Exact<{
  userEmailAddress: Scalars['String']['input'];
}>;


export type SwitchUserToFinancialProviderLoginMutation = { __typename?: 'Mutation', switchUserToFinancialProviderLogin: { __typename?: 'User', id: string } };

export type ResetTargetingBaselineMutationVariables = Exact<{
  baselineId: Scalars['ID']['input'];
}>;


export type ResetTargetingBaselineMutation = { __typename?: 'Mutation', resetTargetingBaseline: string };

export type GetReductionTargetQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetReductionTargetQuery = { __typename?: 'Query', getReductionTarget?: { __typename?: 'ReductionTarget', reduction_target_type: string, baseline_year: number, target_completion_year: number, yearly_percent_reduction: number } | null, getTargetingBaseline?: { __typename?: 'TargetingBaseline', id: string, company_reporting_period: { __typename?: 'CompanyReportingPeriod', period_start: string, period_end: string } } | null };

export type RemoveOverlappingBaselineOpsProfileMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type RemoveOverlappingBaselineOpsProfileMutation = { __typename?: 'Mutation', removeOverlappingBaselineOpsProfile: boolean };

export type GetOverlappingBaselineOpsProfileQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetOverlappingBaselineOpsProfileQuery = { __typename?: 'Query', getOverlappingOpsBaselineProfile: Array<{ __typename?: 'OverlappingBaseline', baseline_profile_id: string, baseline_period_start: string, baseline_period_end: string, ops_profile_id: string, ops_period_start: string, ops_period_end: string, ops_reporting_period_id: string, profile_type: string }> };

export type ResetReductionTargetMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ResetReductionTargetMutation = { __typename?: 'Mutation', resetReductionTarget: { __typename?: 'AdminCompany', id: string } };

export type GetCompanyUsersQueryVariables = Exact<{
  companyAccountId: Scalars['ID']['input'];
}>;


export type GetCompanyUsersQuery = { __typename?: 'Query', getCompanyUsers: Array<{ __typename?: 'User', id: string, name: string, email: string, roles: Array<string>, enabled: boolean }> };

export type ResendInviteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  companyAccountId: Scalars['ID']['input'];
}>;


export type ResendInviteMutation = { __typename?: 'Mutation', resendCompanyUserInvite: { __typename?: 'InviteResponse', code: string, expires_at: any } };

export type TaxonomyTermDisplay_TaxonomyFragment = { __typename?: 'Taxonomy', id: string, name: string, terms: Array<{ __typename?: 'TaxonomyTerm', id: string, name: string }> } & { ' $fragmentName'?: 'TaxonomyTermDisplay_TaxonomyFragment' };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser?: (
    { __typename?: 'User' }
    & { ' $fragmentRefs'?: { 'CurrentUserFragment': CurrentUserFragment } }
  ) | null };

export type CurrentUserFragment = { __typename?: 'User', id: string, name: string, email: string, company_name: string } & { ' $fragmentName'?: 'CurrentUserFragment' };

export type FetchBackgroundExecutionStatusQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type FetchBackgroundExecutionStatusQuery = { __typename?: 'Query', fetchBackgroundExecution?: { __typename?: 'BackgroundExecution', status: string, completed_at?: string | null } | null };

export type GetNewCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNewCompaniesQuery = { __typename?: 'Query', getNewCompanies: Array<{ __typename?: 'AdminCompany', id: string, name?: string | null }> };

export type SearchForCompanyQueryVariables = Exact<{
  query: Scalars['String']['input'];
}>;


export type SearchForCompanyQuery = { __typename?: 'Query', searchForCompany: Array<{ __typename?: 'AdminCompany', id: string, name?: string | null }> };

export type GetCompanyDetailQueryVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type GetCompanyDetailQuery = { __typename?: 'Query', getCompany: { __typename?: 'AdminCompany', id: string, name?: string | null, email: string } };

export type GetStuckSyncsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStuckSyncsQuery = { __typename?: 'Query', getStuckSyncs: Array<{ __typename?: 'FinancialTransactionSync', id: string, created_at: string, sync_type: string, sync_method: string, company_account: { __typename?: 'AdminCompany', name?: string | null } }> };

export type ClearStuckSyncMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type ClearStuckSyncMutation = { __typename?: 'Mutation', clearStuckSync: Array<{ __typename?: 'FinancialTransactionSync', id: string, created_at: string, sync_type: string, sync_method: string, company_account: { __typename?: 'AdminCompany', name?: string | null } }> };

export type GetIdentityProviderUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type GetIdentityProviderUrlMutation = { __typename?: 'Mutation', getIdentityProviderUrl?: { __typename?: 'AuthorisationUrl', url: string } | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type GetAdminUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdminUsersQuery = { __typename?: 'Query', getAdminUsers: Array<{ __typename?: 'User', id: string, name: string, email: string }> };

export const TaxonomyTermDisplay_TaxonomyFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"TaxonomyTermDisplay_Taxonomy"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Taxonomy"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"terms"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<TaxonomyTermDisplay_TaxonomyFragment, unknown>;
export const CurrentUserFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CurrentUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"company_name"}}]}}]} as unknown as DocumentNode<CurrentUserFragment, unknown>;
export const AddCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"company"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddCompanyInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"addCompanyInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"company"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddCompanyMutation, AddCompanyMutationVariables>;
export const IdentityProviderCallbackDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"IdentityProviderCallback"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"params"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"provider"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"IdentityProviderName"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"identityProviderCallback"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"params"}}},{"kind":"Argument","name":{"kind":"Name","value":"provider"},"value":{"kind":"Variable","name":{"kind":"Name","value":"provider"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<IdentityProviderCallbackMutation, IdentityProviderCallbackMutationVariables>;
export const GetCompanyAccountingDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyAccountingData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"financialDataQuality"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"totalTransactions"}},{"kind":"Field","name":{"kind":"Name","value":"totalMerchants"}},{"kind":"Field","name":{"kind":"Name","value":"transactionsWithoutMerchants"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"transaction_date"}},{"kind":"Field","name":{"kind":"Name","value":"amount"}}]}}]}},{"kind":"Field","name":{"kind":"Name","value":"financialProviderSyncStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}},{"kind":"Field","name":{"kind":"Name","value":"completed_at"}},{"kind":"Field","name":{"kind":"Name","value":"sync_type"}},{"kind":"Field","name":{"kind":"Name","value":"sync_method"}},{"kind":"Field","name":{"kind":"Name","value":"sync_status"}}]}}]}}]}}]} as unknown as DocumentNode<GetCompanyAccountingDataQuery, GetCompanyAccountingDataQueryVariables>;
export const ForceCompleteProviderResyncDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ForceCompleteProviderResync"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"financialProvider"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"forceCompleteFinancialProviderResync"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"financialProvider"},"value":{"kind":"Variable","name":{"kind":"Name","value":"financialProvider"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ForceCompleteProviderResyncMutation, ForceCompleteProviderResyncMutationVariables>;
export const ResetCompanyDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetCompanyData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetCompanyData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ResetCompanyDataMutation, ResetCompanyDataMutationVariables>;
export const DisconnectCompanyFinancialProviderDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DisconnectCompanyFinancialProvider"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disconnectCompanyFinancialProvider"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"sync_type"}},{"kind":"Field","name":{"kind":"Name","value":"sync_status"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}}]}}]}}]} as unknown as DocumentNode<DisconnectCompanyFinancialProviderMutation, DisconnectCompanyFinancialProviderMutationVariables>;
export const RefreshCompanyTransactionDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RefreshCompanyTransactionData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"target"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EmissionsRefreshTarget"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"refreshCompanyTransactions"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"target"},"value":{"kind":"Variable","name":{"kind":"Name","value":"target"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<RefreshCompanyTransactionDataMutation, RefreshCompanyTransactionDataMutationVariables>;
export const AddUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"AddUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"AddUserInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"addUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"addUserInput"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<AddUserMutation, AddUserMutationVariables>;
export const GetAccountDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAccount"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"financialAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAccount"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"financialAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"financialAccountId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}}]}}]}}]} as unknown as DocumentNode<GetAccountQuery, GetAccountQueryVariables>;
export const DisableUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DisableUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"user"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disableUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"user"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<DisableUserMutation, DisableUserMutationVariables>;
export const GetCompanyFeatureFlagsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyFeatureFlags"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCompanyFeatureFlags"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"feature_flag"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"reversible"}}]}}]}}]} as unknown as DocumentNode<GetCompanyFeatureFlagsQuery, GetCompanyFeatureFlagsQueryVariables>;
export const EnableCompanyFeatureFlagDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EnableCompanyFeatureFlag"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"featureFlag"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"enableCompanyFeatureFlag"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"featureFlag"},"value":{"kind":"Variable","name":{"kind":"Name","value":"featureFlag"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<EnableCompanyFeatureFlagMutation, EnableCompanyFeatureFlagMutationVariables>;
export const DisableCompanyFeatureFlagDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DisableCompanyFeatureFlag"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"featureFlag"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"disableCompanyFeatureFlag"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"featureFlag"},"value":{"kind":"Variable","name":{"kind":"Name","value":"featureFlag"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<DisableCompanyFeatureFlagMutation, DisableCompanyFeatureFlagMutationVariables>;
export const GetCompanySettingsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanySettings"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCompanyFeatureFlags"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"type"},"value":{"kind":"EnumValue","value":"setting"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}},{"kind":"Field","name":{"kind":"Name","value":"reversible"}}]}}]}}]} as unknown as DocumentNode<GetCompanySettingsQuery, GetCompanySettingsQueryVariables>;
export const GetCompanySubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanySubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"subscription"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stripeSubscriptionId"}},{"kind":"Field","name":{"kind":"Name","value":"isTrial"}},{"kind":"Field","name":{"kind":"Name","value":"trialPeriodEnds"}},{"kind":"Field","name":{"kind":"Name","value":"status"}}]}}]}}]}}]} as unknown as DocumentNode<GetCompanySubscriptionQuery, GetCompanySubscriptionQueryVariables>;
export const ExtendSubscriptionTrialDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ExtendSubscriptionTrial"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"trialEnd"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"DateTime"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"extendSubscriptionTrial"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}},{"kind":"Argument","name":{"kind":"Name","value":"trialEnd"},"value":{"kind":"Variable","name":{"kind":"Name","value":"trialEnd"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"stripeSubscriptionId"}},{"kind":"Field","name":{"kind":"Name","value":"isTrial"}},{"kind":"Field","name":{"kind":"Name","value":"trialPeriodEnds"}}]}}]}}]} as unknown as DocumentNode<ExtendSubscriptionTrialMutation, ExtendSubscriptionTrialMutationVariables>;
export const CancelCompanySubscriptionDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"CancelCompanySubscription"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"cancelCompanySubscription"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<CancelCompanySubscriptionMutation, CancelCompanySubscriptionMutationVariables>;
export const SwitchUserToFinancialProviderLoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"SwitchUserToFinancialProviderLogin"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userEmailAddress"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"switchUserToFinancialProviderLogin"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userEmailAddress"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userEmailAddress"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<SwitchUserToFinancialProviderLoginMutation, SwitchUserToFinancialProviderLoginMutationVariables>;
export const ResetTargetingBaselineDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetTargetingBaseline"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"baselineId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetTargetingBaseline"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"baselineId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"baselineId"}}}]}]}}]} as unknown as DocumentNode<ResetTargetingBaselineMutation, ResetTargetingBaselineMutationVariables>;
export const GetReductionTargetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetReductionTarget"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getReductionTarget"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"reduction_target_type"}},{"kind":"Field","name":{"kind":"Name","value":"baseline_year"}},{"kind":"Field","name":{"kind":"Name","value":"target_completion_year"}},{"kind":"Field","name":{"kind":"Name","value":"yearly_percent_reduction"}}]}},{"kind":"Field","name":{"kind":"Name","value":"getTargetingBaseline"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"company_reporting_period"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"period_start"}},{"kind":"Field","name":{"kind":"Name","value":"period_end"}}]}}]}}]}}]} as unknown as DocumentNode<GetReductionTargetQuery, GetReductionTargetQueryVariables>;
export const RemoveOverlappingBaselineOpsProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"RemoveOverlappingBaselineOpsProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"removeOverlappingBaselineOpsProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}]}]}}]} as unknown as DocumentNode<RemoveOverlappingBaselineOpsProfileMutation, RemoveOverlappingBaselineOpsProfileMutationVariables>;
export const GetOverlappingBaselineOpsProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetOverlappingBaselineOpsProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getOverlappingOpsBaselineProfile"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"baseline_profile_id"}},{"kind":"Field","name":{"kind":"Name","value":"baseline_period_start"}},{"kind":"Field","name":{"kind":"Name","value":"baseline_period_end"}},{"kind":"Field","name":{"kind":"Name","value":"ops_profile_id"}},{"kind":"Field","name":{"kind":"Name","value":"ops_period_start"}},{"kind":"Field","name":{"kind":"Name","value":"ops_period_end"}},{"kind":"Field","name":{"kind":"Name","value":"ops_reporting_period_id"}},{"kind":"Field","name":{"kind":"Name","value":"profile_type"}}]}}]}}]} as unknown as DocumentNode<GetOverlappingBaselineOpsProfileQuery, GetOverlappingBaselineOpsProfileQueryVariables>;
export const ResetReductionTargetDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResetReductionTarget"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resetReductionTarget"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}}]} as unknown as DocumentNode<ResetReductionTargetMutation, ResetReductionTargetMutationVariables>;
export const GetCompanyUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyUsers"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCompanyUsers"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"roles"}},{"kind":"Field","name":{"kind":"Name","value":"enabled"}}]}}]}}]} as unknown as DocumentNode<GetCompanyUsersQuery, GetCompanyUsersQueryVariables>;
export const ResendInviteDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ResendInvite"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"email"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"resendCompanyUserInvite"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"userEmail"},"value":{"kind":"Variable","name":{"kind":"Name","value":"email"}}},{"kind":"Argument","name":{"kind":"Name","value":"companyAccountId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"companyAccountId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"code"}},{"kind":"Field","name":{"kind":"Name","value":"expires_at"}}]}}]}}]} as unknown as DocumentNode<ResendInviteMutation, ResendInviteMutationVariables>;
export const GetCurrentUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCurrentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"currentUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentUser"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CurrentUser"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}},{"kind":"Field","name":{"kind":"Name","value":"company_name"}}]}}]} as unknown as DocumentNode<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const FetchBackgroundExecutionStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"FetchBackgroundExecutionStatus"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"fetchBackgroundExecution"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"status"}},{"kind":"Field","name":{"kind":"Name","value":"completed_at"}}]}}]}}]} as unknown as DocumentNode<FetchBackgroundExecutionStatusQuery, FetchBackgroundExecutionStatusQueryVariables>;
export const GetNewCompaniesDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetNewCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getNewCompanies"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<GetNewCompaniesQuery, GetNewCompaniesQueryVariables>;
export const SearchForCompanyDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"SearchForCompany"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"query"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"searchForCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"query"},"value":{"kind":"Variable","name":{"kind":"Name","value":"query"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]} as unknown as DocumentNode<SearchForCompanyQuery, SearchForCompanyQueryVariables>;
export const GetCompanyDetailDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetCompanyDetail"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getCompany"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<GetCompanyDetailQuery, GetCompanyDetailQueryVariables>;
export const GetStuckSyncsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetStuckSyncs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getStuckSyncs"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}},{"kind":"Field","name":{"kind":"Name","value":"sync_type"}},{"kind":"Field","name":{"kind":"Name","value":"sync_method"}},{"kind":"Field","name":{"kind":"Name","value":"company_account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<GetStuckSyncsQuery, GetStuckSyncsQueryVariables>;
export const ClearStuckSyncDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ClearStuckSync"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"clearStuckSync"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"created_at"}},{"kind":"Field","name":{"kind":"Name","value":"sync_type"}},{"kind":"Field","name":{"kind":"Name","value":"sync_method"}},{"kind":"Field","name":{"kind":"Name","value":"company_account"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"name"}}]}}]}}]}}]} as unknown as DocumentNode<ClearStuckSyncMutation, ClearStuckSyncMutationVariables>;
export const GetIdentityProviderUrlDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"GetIdentityProviderUrl"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getIdentityProviderUrl"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"url"}}]}}]}}]} as unknown as DocumentNode<GetIdentityProviderUrlMutation, GetIdentityProviderUrlMutationVariables>;
export const LogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Logout"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"}}]}}]} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const GetAdminUsersDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAdminUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"getAdminUsers"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]} as unknown as DocumentNode<GetAdminUsersQuery, GetAdminUsersQueryVariables>;
export type AdminCompanyKeySpecifier = ('email' | 'financialDataQuality' | 'financialProviderSyncStatus' | 'id' | 'name' | 'subscription' | AdminCompanyKeySpecifier)[];
export type AdminCompanyFieldPolicy = {
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	financialDataQuality?: FieldPolicy<any> | FieldReadFunction<any>,
	financialProviderSyncStatus?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	subscription?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AdminMerchantKeySpecifier = ('aliases' | 'carbonIntensities' | 'created_at' | 'id' | 'identified' | 'matchers' | 'metadata' | 'name' | 'sicCodes' | 'taxonomy' | 'transactions' | AdminMerchantKeySpecifier)[];
export type AdminMerchantFieldPolicy = {
	aliases?: FieldPolicy<any> | FieldReadFunction<any>,
	carbonIntensities?: FieldPolicy<any> | FieldReadFunction<any>,
	created_at?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	identified?: FieldPolicy<any> | FieldReadFunction<any>,
	matchers?: FieldPolicy<any> | FieldReadFunction<any>,
	metadata?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	sicCodes?: FieldPolicy<any> | FieldReadFunction<any>,
	taxonomy?: FieldPolicy<any> | FieldReadFunction<any>,
	transactions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AdminMerchantConnectionKeySpecifier = ('edges' | 'pageInfo' | AdminMerchantConnectionKeySpecifier)[];
export type AdminMerchantConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AdminMerchantEdgeKeySpecifier = ('cursor' | 'node' | AdminMerchantEdgeKeySpecifier)[];
export type AdminMerchantEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AdminSubscriptionKeySpecifier = ('id' | 'isTrial' | 'status' | 'stripeSubscriptionId' | 'trialPeriodEnds' | AdminSubscriptionKeySpecifier)[];
export type AdminSubscriptionFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isTrial?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	stripeSubscriptionId?: FieldPolicy<any> | FieldReadFunction<any>,
	trialPeriodEnds?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AdminTransactionKeySpecifier = ('amount' | 'description' | 'financial_account' | 'id' | 'merchant' | 'merchant_id' | 'transaction_date' | AdminTransactionKeySpecifier)[];
export type AdminTransactionFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	financial_account?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	merchant?: FieldPolicy<any> | FieldReadFunction<any>,
	merchant_id?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_date?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AdminTransactionConnectionKeySpecifier = ('edges' | 'pageInfo' | AdminTransactionConnectionKeySpecifier)[];
export type AdminTransactionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AdminTransactionEdgeKeySpecifier = ('cursor' | 'node' | AdminTransactionEdgeKeySpecifier)[];
export type AdminTransactionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type AuthorisationUrlKeySpecifier = ('url' | AuthorisationUrlKeySpecifier)[];
export type AuthorisationUrlFieldPolicy = {
	url?: FieldPolicy<any> | FieldReadFunction<any>
};
export type BackgroundExecutionKeySpecifier = ('completed_at' | 'created_at' | 'execution_type' | 'id' | 'status' | 'updated_at' | 'upload_errors' | 'user_account_id' | BackgroundExecutionKeySpecifier)[];
export type BackgroundExecutionFieldPolicy = {
	completed_at?: FieldPolicy<any> | FieldReadFunction<any>,
	created_at?: FieldPolicy<any> | FieldReadFunction<any>,
	execution_type?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	updated_at?: FieldPolicy<any> | FieldReadFunction<any>,
	upload_errors?: FieldPolicy<any> | FieldReadFunction<any>,
	user_account_id?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CarbonIntensityKeySpecifier = ('carbon_intensity' | 'id' | 'reference_url' | 'year' | CarbonIntensityKeySpecifier)[];
export type CarbonIntensityFieldPolicy = {
	carbon_intensity?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	reference_url?: FieldPolicy<any> | FieldReadFunction<any>,
	year?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompaniesHouseSearchResultKeySpecifier = ('company_number' | 'company_status' | 'company_type' | 'date_of_cessation' | 'date_of_creation' | 'description' | 'title' | CompaniesHouseSearchResultKeySpecifier)[];
export type CompaniesHouseSearchResultFieldPolicy = {
	company_number?: FieldPolicy<any> | FieldReadFunction<any>,
	company_status?: FieldPolicy<any> | FieldReadFunction<any>,
	company_type?: FieldPolicy<any> | FieldReadFunction<any>,
	date_of_cessation?: FieldPolicy<any> | FieldReadFunction<any>,
	date_of_creation?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	title?: FieldPolicy<any> | FieldReadFunction<any>
};
export type CompanyReportingPeriodKeySpecifier = ('company_account_id' | 'energy' | 'ghg' | 'id' | 'isBaselineReportingPeriod' | 'isCompleted' | 'isOpen' | 'people' | 'period_end' | 'period_start' | 'totalEmissionsForPeriod' | 'vehicles' | CompanyReportingPeriodKeySpecifier)[];
export type CompanyReportingPeriodFieldPolicy = {
	company_account_id?: FieldPolicy<any> | FieldReadFunction<any>,
	energy?: FieldPolicy<any> | FieldReadFunction<any>,
	ghg?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	isBaselineReportingPeriod?: FieldPolicy<any> | FieldReadFunction<any>,
	isCompleted?: FieldPolicy<any> | FieldReadFunction<any>,
	isOpen?: FieldPolicy<any> | FieldReadFunction<any>,
	people?: FieldPolicy<any> | FieldReadFunction<any>,
	period_end?: FieldPolicy<any> | FieldReadFunction<any>,
	period_start?: FieldPolicy<any> | FieldReadFunction<any>,
	totalEmissionsForPeriod?: FieldPolicy<any> | FieldReadFunction<any>,
	vehicles?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FeatureFlagKeySpecifier = ('description' | 'enabled' | 'id' | 'name' | 'reversible' | 'type' | FeatureFlagKeySpecifier)[];
export type FeatureFlagFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	enabled?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	reversible?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FileRequestDetailKeySpecifier = ('file_extension' | 'filename' | 'id' | 'upload_request_type' | FileRequestDetailKeySpecifier)[];
export type FileRequestDetailFieldPolicy = {
	file_extension?: FieldPolicy<any> | FieldReadFunction<any>,
	filename?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	upload_request_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FinancialAccountKeySpecifier = ('description' | 'id' | 'name' | 'terms' | FinancialAccountKeySpecifier)[];
export type FinancialAccountFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	terms?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FinancialDataQualityKeySpecifier = ('status' | 'totalMerchants' | 'totalTransactions' | 'transactionsWithoutMerchants' | 'unidentifiedMerchants' | FinancialDataQualityKeySpecifier)[];
export type FinancialDataQualityFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	totalMerchants?: FieldPolicy<any> | FieldReadFunction<any>,
	totalTransactions?: FieldPolicy<any> | FieldReadFunction<any>,
	transactionsWithoutMerchants?: FieldPolicy<any> | FieldReadFunction<any>,
	unidentifiedMerchants?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FinancialProviderKeySpecifier = ('connection_status' | 'financial_provider' | 'sync_method' | 'sync_status' | FinancialProviderKeySpecifier)[];
export type FinancialProviderFieldPolicy = {
	connection_status?: FieldPolicy<any> | FieldReadFunction<any>,
	financial_provider?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_method?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FinancialProviderSyncKeySpecifier = ('completed_at' | 'created_at' | 'id' | 'sync_method' | 'sync_status' | 'sync_type' | FinancialProviderSyncKeySpecifier)[];
export type FinancialProviderSyncFieldPolicy = {
	completed_at?: FieldPolicy<any> | FieldReadFunction<any>,
	created_at?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_method?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_status?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FinancialTransactionSyncKeySpecifier = ('company_account' | 'company_account_id' | 'created_at' | 'id' | 'sync_method' | 'sync_type' | FinancialTransactionSyncKeySpecifier)[];
export type FinancialTransactionSyncFieldPolicy = {
	company_account?: FieldPolicy<any> | FieldReadFunction<any>,
	company_account_id?: FieldPolicy<any> | FieldReadFunction<any>,
	created_at?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_method?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type FreeForLifeCompanySubscriptionKeySpecifier = ('status' | FreeForLifeCompanySubscriptionKeySpecifier)[];
export type FreeForLifeCompanySubscriptionFieldPolicy = {
	status?: FieldPolicy<any> | FieldReadFunction<any>
};
export type InviteResponseKeySpecifier = ('code' | 'expires_at' | InviteResponseKeySpecifier)[];
export type InviteResponseFieldPolicy = {
	code?: FieldPolicy<any> | FieldReadFunction<any>,
	expires_at?: FieldPolicy<any> | FieldReadFunction<any>
};
export type LastSyncKeySpecifier = ('completed_at' | 'sync_type' | LastSyncKeySpecifier)[];
export type LastSyncFieldPolicy = {
	completed_at?: FieldPolicy<any> | FieldReadFunction<any>,
	sync_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MatcherKeySpecifier = ('enabled' | 'id' | 'notes' | 'query' | MatcherKeySpecifier)[];
export type MatcherFieldPolicy = {
	enabled?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	notes?: FieldPolicy<any> | FieldReadFunction<any>,
	query?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MerchantKeySpecifier = ('created_at' | 'id' | 'identified' | 'name' | 'transactions' | MerchantKeySpecifier)[];
export type MerchantFieldPolicy = {
	created_at?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	identified?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	transactions?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MerchantAliasKeySpecifier = ('id' | 'name_alias' | MerchantAliasKeySpecifier)[];
export type MerchantAliasFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name_alias?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MerchantBaseKeySpecifier = ('created_at' | 'id' | 'identified' | 'name' | MerchantBaseKeySpecifier)[];
export type MerchantBaseFieldPolicy = {
	created_at?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	identified?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MerchantMetadataKeySpecifier = ('id' | 'metadata_type' | 'metadata_value' | MerchantMetadataKeySpecifier)[];
export type MerchantMetadataFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	metadata_type?: FieldPolicy<any> | FieldReadFunction<any>,
	metadata_value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MerchantTaxonomyKeySpecifier = ('overrideTaxonomyTerm' | 'suggestedTaxonomyTerm' | MerchantTaxonomyKeySpecifier)[];
export type MerchantTaxonomyFieldPolicy = {
	overrideTaxonomyTerm?: FieldPolicy<any> | FieldReadFunction<any>,
	suggestedTaxonomyTerm?: FieldPolicy<any> | FieldReadFunction<any>
};
export type MutationKeySpecifier = ('addCompany' | 'addMatcherToMerchant' | 'addMerchantCarbonIntensity' | 'addUser' | 'cancelCompanySubscription' | 'cancelSubscription' | 'clearStuckSync' | 'createMerchantFromTransaction' | 'createSubscription' | 'disableCompanyFeatureFlag' | 'disableUser' | 'disconnectCompanyFinancialProvider' | 'editMerchantTaxonomyTerms' | 'enableCompanyFeatureFlag' | 'extendSubscriptionTrial' | 'forceCompleteFinancialProviderResync' | 'getIdentityProviderUrl' | 'identityProviderCallback' | 'logout' | 'mergeMerchant' | 'refreshCompanyTransactions' | 'removeMerchantCarbonIntensity' | 'removeOverlappingBaselineOpsProfile' | 'resendCompanyUserInvite' | 'resetCompanyData' | 'resetReductionTarget' | 'resetTargetingBaseline' | 'setCompaniesHouseIdForMerchant' | 'setMerchantMetadataItem' | 'setMerchantSicCodes' | 'switchUserToFinancialProviderLogin' | 'updateDefaultSubscriptionPaymentMethod' | 'updateMatcherForMerchant' | 'updateTermsAndConditionsApproval' | MutationKeySpecifier)[];
export type MutationFieldPolicy = {
	addCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	addMatcherToMerchant?: FieldPolicy<any> | FieldReadFunction<any>,
	addMerchantCarbonIntensity?: FieldPolicy<any> | FieldReadFunction<any>,
	addUser?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelCompanySubscription?: FieldPolicy<any> | FieldReadFunction<any>,
	cancelSubscription?: FieldPolicy<any> | FieldReadFunction<any>,
	clearStuckSync?: FieldPolicy<any> | FieldReadFunction<any>,
	createMerchantFromTransaction?: FieldPolicy<any> | FieldReadFunction<any>,
	createSubscription?: FieldPolicy<any> | FieldReadFunction<any>,
	disableCompanyFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>,
	disableUser?: FieldPolicy<any> | FieldReadFunction<any>,
	disconnectCompanyFinancialProvider?: FieldPolicy<any> | FieldReadFunction<any>,
	editMerchantTaxonomyTerms?: FieldPolicy<any> | FieldReadFunction<any>,
	enableCompanyFeatureFlag?: FieldPolicy<any> | FieldReadFunction<any>,
	extendSubscriptionTrial?: FieldPolicy<any> | FieldReadFunction<any>,
	forceCompleteFinancialProviderResync?: FieldPolicy<any> | FieldReadFunction<any>,
	getIdentityProviderUrl?: FieldPolicy<any> | FieldReadFunction<any>,
	identityProviderCallback?: FieldPolicy<any> | FieldReadFunction<any>,
	logout?: FieldPolicy<any> | FieldReadFunction<any>,
	mergeMerchant?: FieldPolicy<any> | FieldReadFunction<any>,
	refreshCompanyTransactions?: FieldPolicy<any> | FieldReadFunction<any>,
	removeMerchantCarbonIntensity?: FieldPolicy<any> | FieldReadFunction<any>,
	removeOverlappingBaselineOpsProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	resendCompanyUserInvite?: FieldPolicy<any> | FieldReadFunction<any>,
	resetCompanyData?: FieldPolicy<any> | FieldReadFunction<any>,
	resetReductionTarget?: FieldPolicy<any> | FieldReadFunction<any>,
	resetTargetingBaseline?: FieldPolicy<any> | FieldReadFunction<any>,
	setCompaniesHouseIdForMerchant?: FieldPolicy<any> | FieldReadFunction<any>,
	setMerchantMetadataItem?: FieldPolicy<any> | FieldReadFunction<any>,
	setMerchantSicCodes?: FieldPolicy<any> | FieldReadFunction<any>,
	switchUserToFinancialProviderLogin?: FieldPolicy<any> | FieldReadFunction<any>,
	updateDefaultSubscriptionPaymentMethod?: FieldPolicy<any> | FieldReadFunction<any>,
	updateMatcherForMerchant?: FieldPolicy<any> | FieldReadFunction<any>,
	updateTermsAndConditionsApproval?: FieldPolicy<any> | FieldReadFunction<any>
};
export type OverlappingBaselineKeySpecifier = ('baseline_period_end' | 'baseline_period_start' | 'baseline_profile_id' | 'company_account_id' | 'ops_period_end' | 'ops_period_start' | 'ops_profile_id' | 'ops_reporting_period_id' | 'profile_type' | OverlappingBaselineKeySpecifier)[];
export type OverlappingBaselineFieldPolicy = {
	baseline_period_end?: FieldPolicy<any> | FieldReadFunction<any>,
	baseline_period_start?: FieldPolicy<any> | FieldReadFunction<any>,
	baseline_profile_id?: FieldPolicy<any> | FieldReadFunction<any>,
	company_account_id?: FieldPolicy<any> | FieldReadFunction<any>,
	ops_period_end?: FieldPolicy<any> | FieldReadFunction<any>,
	ops_period_start?: FieldPolicy<any> | FieldReadFunction<any>,
	ops_profile_id?: FieldPolicy<any> | FieldReadFunction<any>,
	ops_reporting_period_id?: FieldPolicy<any> | FieldReadFunction<any>,
	profile_type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type PageInfoKeySpecifier = ('endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | PageInfoKeySpecifier)[];
export type PageInfoFieldPolicy = {
	endCursor?: FieldPolicy<any> | FieldReadFunction<any>,
	hasNextPage?: FieldPolicy<any> | FieldReadFunction<any>,
	hasPreviousPage?: FieldPolicy<any> | FieldReadFunction<any>,
	startCursor?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProfileBenchmarkKeySpecifier = ('benchmarkKey' | 'benchmarkText' | 'benchmarkTextUnit' | 'reference' | 'value' | ProfileBenchmarkKeySpecifier)[];
export type ProfileBenchmarkFieldPolicy = {
	benchmarkKey?: FieldPolicy<any> | FieldReadFunction<any>,
	benchmarkText?: FieldPolicy<any> | FieldReadFunction<any>,
	benchmarkTextUnit?: FieldPolicy<any> | FieldReadFunction<any>,
	reference?: FieldPolicy<any> | FieldReadFunction<any>,
	value?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProfileEntryKeySpecifier = ('benchmark' | 'completed_at' | 'entry_type' | 'id' | 'kgCo2e' | ProfileEntryKeySpecifier)[];
export type ProfileEntryFieldPolicy = {
	benchmark?: FieldPolicy<any> | FieldReadFunction<any>,
	completed_at?: FieldPolicy<any> | FieldReadFunction<any>,
	entry_type?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	kgCo2e?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ProviderFinancialAccountKeySpecifier = ('description' | 'id' | 'name' | ProviderFinancialAccountKeySpecifier)[];
export type ProviderFinancialAccountFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>
};
export type QueryKeySpecifier = ('currentUser' | 'fetchBackgroundExecution' | 'fetchLastBackgroundExecution' | 'getAccount' | 'getAdminMerchantById' | 'getAdminUsers' | 'getAllTaxonomies' | 'getAllUnidentifiedMerchants' | 'getCompany' | 'getCompanyFeatureFlags' | 'getCompanyUsers' | 'getNewCompanies' | 'getOverlappingOpsBaselineProfile' | 'getReductionTarget' | 'getSIBSEBuildingTypes' | 'getSicCodes' | 'getStuckSyncs' | 'getSubscriptionAuthenticationParams' | 'getSubscriptionPromotionalCode' | 'getSubscriptionTrialPeriodDays' | 'getTargetingBaseline' | 'getTransactionsWithoutMerchants' | 'listSubscriptionProducts' | 'searchCompaniesHouse' | 'searchForCompany' | 'searchForMerchant' | QueryKeySpecifier)[];
export type QueryFieldPolicy = {
	currentUser?: FieldPolicy<any> | FieldReadFunction<any>,
	fetchBackgroundExecution?: FieldPolicy<any> | FieldReadFunction<any>,
	fetchLastBackgroundExecution?: FieldPolicy<any> | FieldReadFunction<any>,
	getAccount?: FieldPolicy<any> | FieldReadFunction<any>,
	getAdminMerchantById?: FieldPolicy<any> | FieldReadFunction<any>,
	getAdminUsers?: FieldPolicy<any> | FieldReadFunction<any>,
	getAllTaxonomies?: FieldPolicy<any> | FieldReadFunction<any>,
	getAllUnidentifiedMerchants?: FieldPolicy<any> | FieldReadFunction<any>,
	getCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	getCompanyFeatureFlags?: FieldPolicy<any> | FieldReadFunction<any>,
	getCompanyUsers?: FieldPolicy<any> | FieldReadFunction<any>,
	getNewCompanies?: FieldPolicy<any> | FieldReadFunction<any>,
	getOverlappingOpsBaselineProfile?: FieldPolicy<any> | FieldReadFunction<any>,
	getReductionTarget?: FieldPolicy<any> | FieldReadFunction<any>,
	getSIBSEBuildingTypes?: FieldPolicy<any> | FieldReadFunction<any>,
	getSicCodes?: FieldPolicy<any> | FieldReadFunction<any>,
	getStuckSyncs?: FieldPolicy<any> | FieldReadFunction<any>,
	getSubscriptionAuthenticationParams?: FieldPolicy<any> | FieldReadFunction<any>,
	getSubscriptionPromotionalCode?: FieldPolicy<any> | FieldReadFunction<any>,
	getSubscriptionTrialPeriodDays?: FieldPolicy<any> | FieldReadFunction<any>,
	getTargetingBaseline?: FieldPolicy<any> | FieldReadFunction<any>,
	getTransactionsWithoutMerchants?: FieldPolicy<any> | FieldReadFunction<any>,
	listSubscriptionProducts?: FieldPolicy<any> | FieldReadFunction<any>,
	searchCompaniesHouse?: FieldPolicy<any> | FieldReadFunction<any>,
	searchForCompany?: FieldPolicy<any> | FieldReadFunction<any>,
	searchForMerchant?: FieldPolicy<any> | FieldReadFunction<any>
};
export type RecurringCompanySubscriptionKeySpecifier = ('cancellation_period_over' | 'expires_at' | 'failure_reason' | 'id' | 'in_trial_period' | 'payment_status' | 'status' | 'updated_at' | RecurringCompanySubscriptionKeySpecifier)[];
export type RecurringCompanySubscriptionFieldPolicy = {
	cancellation_period_over?: FieldPolicy<any> | FieldReadFunction<any>,
	expires_at?: FieldPolicy<any> | FieldReadFunction<any>,
	failure_reason?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	in_trial_period?: FieldPolicy<any> | FieldReadFunction<any>,
	payment_status?: FieldPolicy<any> | FieldReadFunction<any>,
	status?: FieldPolicy<any> | FieldReadFunction<any>,
	updated_at?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ReductionTargetKeySpecifier = ('baseline_year' | 'id' | 'reduction_target_type' | 'target_completion_year' | 'yearly_percent_reduction' | ReductionTargetKeySpecifier)[];
export type ReductionTargetFieldPolicy = {
	baseline_year?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	reduction_target_type?: FieldPolicy<any> | FieldReadFunction<any>,
	target_completion_year?: FieldPolicy<any> | FieldReadFunction<any>,
	yearly_percent_reduction?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SIBSEBuildingTypeKeySpecifier = ('id' | 'label' | SIBSEBuildingTypeKeySpecifier)[];
export type SIBSEBuildingTypeFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	label?: FieldPolicy<any> | FieldReadFunction<any>
};
export type ScopeCo2eBreakdownKeySpecifier = ('scope_1_kg_co2e' | 'scope_2_kg_co2e' | 'scope_3_kg_co2e' | 'total_kg_co2e' | ScopeCo2eBreakdownKeySpecifier)[];
export type ScopeCo2eBreakdownFieldPolicy = {
	scope_1_kg_co2e?: FieldPolicy<any> | FieldReadFunction<any>,
	scope_2_kg_co2e?: FieldPolicy<any> | FieldReadFunction<any>,
	scope_3_kg_co2e?: FieldPolicy<any> | FieldReadFunction<any>,
	total_kg_co2e?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SicCodeKeySpecifier = ('description' | 'sic_code' | SicCodeKeySpecifier)[];
export type SicCodeFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	sic_code?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubscriptionAuthenticationParamsKeySpecifier = ('client_secret' | 'failure_reason' | SubscriptionAuthenticationParamsKeySpecifier)[];
export type SubscriptionAuthenticationParamsFieldPolicy = {
	client_secret?: FieldPolicy<any> | FieldReadFunction<any>,
	failure_reason?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubscriptionCreationResponseKeySpecifier = ('clientSecret' | 'type' | SubscriptionCreationResponseKeySpecifier)[];
export type SubscriptionCreationResponseFieldPolicy = {
	clientSecret?: FieldPolicy<any> | FieldReadFunction<any>,
	type?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubscriptionProductKeySpecifier = ('amount' | 'name' | 'priceId' | 'productId' | 'recurringPeriod' | 'recurringPeriodAmount' | SubscriptionProductKeySpecifier)[];
export type SubscriptionProductFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	priceId?: FieldPolicy<any> | FieldReadFunction<any>,
	productId?: FieldPolicy<any> | FieldReadFunction<any>,
	recurringPeriod?: FieldPolicy<any> | FieldReadFunction<any>,
	recurringPeriodAmount?: FieldPolicy<any> | FieldReadFunction<any>
};
export type SubscriptionPromotionalCodeKeySpecifier = ('amount_off' | 'applies_to_products' | 'duration' | 'duration_in_months' | 'id' | 'name' | 'percent_off' | SubscriptionPromotionalCodeKeySpecifier)[];
export type SubscriptionPromotionalCodeFieldPolicy = {
	amount_off?: FieldPolicy<any> | FieldReadFunction<any>,
	applies_to_products?: FieldPolicy<any> | FieldReadFunction<any>,
	duration?: FieldPolicy<any> | FieldReadFunction<any>,
	duration_in_months?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	percent_off?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TargetingBaselineKeySpecifier = ('company_reporting_period' | 'company_reporting_period_id' | 'id' | 'scope_co2e_breakdown' | TargetingBaselineKeySpecifier)[];
export type TargetingBaselineFieldPolicy = {
	company_reporting_period?: FieldPolicy<any> | FieldReadFunction<any>,
	company_reporting_period_id?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	scope_co2e_breakdown?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaxonomyKeySpecifier = ('id' | 'name' | 'terms' | TaxonomyKeySpecifier)[];
export type TaxonomyFieldPolicy = {
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	terms?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TaxonomyTermKeySpecifier = ('description' | 'id' | 'name' | 'taxonomyKey' | TaxonomyTermKeySpecifier)[];
export type TaxonomyTermFieldPolicy = {
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	taxonomyKey?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionKeySpecifier = ('amount' | 'description' | 'id' | 'merchant' | 'merchant_id' | 'transaction_date' | TransactionKeySpecifier)[];
export type TransactionFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	merchant?: FieldPolicy<any> | FieldReadFunction<any>,
	merchant_id?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_date?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionBaseKeySpecifier = ('amount' | 'description' | 'id' | 'merchant' | 'merchant_id' | 'transaction_date' | TransactionBaseKeySpecifier)[];
export type TransactionBaseFieldPolicy = {
	amount?: FieldPolicy<any> | FieldReadFunction<any>,
	description?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	merchant?: FieldPolicy<any> | FieldReadFunction<any>,
	merchant_id?: FieldPolicy<any> | FieldReadFunction<any>,
	transaction_date?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionConnectionKeySpecifier = ('edges' | 'pageInfo' | TransactionConnectionKeySpecifier)[];
export type TransactionConnectionFieldPolicy = {
	edges?: FieldPolicy<any> | FieldReadFunction<any>,
	pageInfo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type TransactionEdgeKeySpecifier = ('cursor' | 'node' | TransactionEdgeKeySpecifier)[];
export type TransactionEdgeFieldPolicy = {
	cursor?: FieldPolicy<any> | FieldReadFunction<any>,
	node?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UploadValidationErrorsKeySpecifier = ('code' | 'file_upload_request' | 'header' | 'id' | 'line' | 'message' | 'sheetName' | 'sheetNo' | UploadValidationErrorsKeySpecifier)[];
export type UploadValidationErrorsFieldPolicy = {
	code?: FieldPolicy<any> | FieldReadFunction<any>,
	file_upload_request?: FieldPolicy<any> | FieldReadFunction<any>,
	header?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	line?: FieldPolicy<any> | FieldReadFunction<any>,
	message?: FieldPolicy<any> | FieldReadFunction<any>,
	sheetName?: FieldPolicy<any> | FieldReadFunction<any>,
	sheetNo?: FieldPolicy<any> | FieldReadFunction<any>
};
export type UserKeySpecifier = ('company_account_id' | 'company_name' | 'created_at' | 'email' | 'enabled' | 'feature_flags' | 'financialProvider' | 'hasTransactions' | 'id' | 'intercom_hash' | 'isCompanyOnboarded' | 'isEmailVerified' | 'isLeadCompanyUser' | 'lastSync' | 'name' | 'roles' | 'subscription' | 'terms_and_conditions_accepted' | UserKeySpecifier)[];
export type UserFieldPolicy = {
	company_account_id?: FieldPolicy<any> | FieldReadFunction<any>,
	company_name?: FieldPolicy<any> | FieldReadFunction<any>,
	created_at?: FieldPolicy<any> | FieldReadFunction<any>,
	email?: FieldPolicy<any> | FieldReadFunction<any>,
	enabled?: FieldPolicy<any> | FieldReadFunction<any>,
	feature_flags?: FieldPolicy<any> | FieldReadFunction<any>,
	financialProvider?: FieldPolicy<any> | FieldReadFunction<any>,
	hasTransactions?: FieldPolicy<any> | FieldReadFunction<any>,
	id?: FieldPolicy<any> | FieldReadFunction<any>,
	intercom_hash?: FieldPolicy<any> | FieldReadFunction<any>,
	isCompanyOnboarded?: FieldPolicy<any> | FieldReadFunction<any>,
	isEmailVerified?: FieldPolicy<any> | FieldReadFunction<any>,
	isLeadCompanyUser?: FieldPolicy<any> | FieldReadFunction<any>,
	lastSync?: FieldPolicy<any> | FieldReadFunction<any>,
	name?: FieldPolicy<any> | FieldReadFunction<any>,
	roles?: FieldPolicy<any> | FieldReadFunction<any>,
	subscription?: FieldPolicy<any> | FieldReadFunction<any>,
	terms_and_conditions_accepted?: FieldPolicy<any> | FieldReadFunction<any>
};
export type StrictTypedTypePolicies = {
	AdminCompany?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminCompanyKeySpecifier | (() => undefined | AdminCompanyKeySpecifier),
		fields?: AdminCompanyFieldPolicy,
	},
	AdminMerchant?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminMerchantKeySpecifier | (() => undefined | AdminMerchantKeySpecifier),
		fields?: AdminMerchantFieldPolicy,
	},
	AdminMerchantConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminMerchantConnectionKeySpecifier | (() => undefined | AdminMerchantConnectionKeySpecifier),
		fields?: AdminMerchantConnectionFieldPolicy,
	},
	AdminMerchantEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminMerchantEdgeKeySpecifier | (() => undefined | AdminMerchantEdgeKeySpecifier),
		fields?: AdminMerchantEdgeFieldPolicy,
	},
	AdminSubscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminSubscriptionKeySpecifier | (() => undefined | AdminSubscriptionKeySpecifier),
		fields?: AdminSubscriptionFieldPolicy,
	},
	AdminTransaction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminTransactionKeySpecifier | (() => undefined | AdminTransactionKeySpecifier),
		fields?: AdminTransactionFieldPolicy,
	},
	AdminTransactionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminTransactionConnectionKeySpecifier | (() => undefined | AdminTransactionConnectionKeySpecifier),
		fields?: AdminTransactionConnectionFieldPolicy,
	},
	AdminTransactionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AdminTransactionEdgeKeySpecifier | (() => undefined | AdminTransactionEdgeKeySpecifier),
		fields?: AdminTransactionEdgeFieldPolicy,
	},
	AuthorisationUrl?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | AuthorisationUrlKeySpecifier | (() => undefined | AuthorisationUrlKeySpecifier),
		fields?: AuthorisationUrlFieldPolicy,
	},
	BackgroundExecution?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | BackgroundExecutionKeySpecifier | (() => undefined | BackgroundExecutionKeySpecifier),
		fields?: BackgroundExecutionFieldPolicy,
	},
	CarbonIntensity?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CarbonIntensityKeySpecifier | (() => undefined | CarbonIntensityKeySpecifier),
		fields?: CarbonIntensityFieldPolicy,
	},
	CompaniesHouseSearchResult?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompaniesHouseSearchResultKeySpecifier | (() => undefined | CompaniesHouseSearchResultKeySpecifier),
		fields?: CompaniesHouseSearchResultFieldPolicy,
	},
	CompanyReportingPeriod?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | CompanyReportingPeriodKeySpecifier | (() => undefined | CompanyReportingPeriodKeySpecifier),
		fields?: CompanyReportingPeriodFieldPolicy,
	},
	FeatureFlag?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FeatureFlagKeySpecifier | (() => undefined | FeatureFlagKeySpecifier),
		fields?: FeatureFlagFieldPolicy,
	},
	FileRequestDetail?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FileRequestDetailKeySpecifier | (() => undefined | FileRequestDetailKeySpecifier),
		fields?: FileRequestDetailFieldPolicy,
	},
	FinancialAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FinancialAccountKeySpecifier | (() => undefined | FinancialAccountKeySpecifier),
		fields?: FinancialAccountFieldPolicy,
	},
	FinancialDataQuality?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FinancialDataQualityKeySpecifier | (() => undefined | FinancialDataQualityKeySpecifier),
		fields?: FinancialDataQualityFieldPolicy,
	},
	FinancialProvider?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FinancialProviderKeySpecifier | (() => undefined | FinancialProviderKeySpecifier),
		fields?: FinancialProviderFieldPolicy,
	},
	FinancialProviderSync?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FinancialProviderSyncKeySpecifier | (() => undefined | FinancialProviderSyncKeySpecifier),
		fields?: FinancialProviderSyncFieldPolicy,
	},
	FinancialTransactionSync?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FinancialTransactionSyncKeySpecifier | (() => undefined | FinancialTransactionSyncKeySpecifier),
		fields?: FinancialTransactionSyncFieldPolicy,
	},
	FreeForLifeCompanySubscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | FreeForLifeCompanySubscriptionKeySpecifier | (() => undefined | FreeForLifeCompanySubscriptionKeySpecifier),
		fields?: FreeForLifeCompanySubscriptionFieldPolicy,
	},
	InviteResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | InviteResponseKeySpecifier | (() => undefined | InviteResponseKeySpecifier),
		fields?: InviteResponseFieldPolicy,
	},
	LastSync?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | LastSyncKeySpecifier | (() => undefined | LastSyncKeySpecifier),
		fields?: LastSyncFieldPolicy,
	},
	Matcher?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MatcherKeySpecifier | (() => undefined | MatcherKeySpecifier),
		fields?: MatcherFieldPolicy,
	},
	Merchant?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MerchantKeySpecifier | (() => undefined | MerchantKeySpecifier),
		fields?: MerchantFieldPolicy,
	},
	MerchantAlias?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MerchantAliasKeySpecifier | (() => undefined | MerchantAliasKeySpecifier),
		fields?: MerchantAliasFieldPolicy,
	},
	MerchantBase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MerchantBaseKeySpecifier | (() => undefined | MerchantBaseKeySpecifier),
		fields?: MerchantBaseFieldPolicy,
	},
	MerchantMetadata?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MerchantMetadataKeySpecifier | (() => undefined | MerchantMetadataKeySpecifier),
		fields?: MerchantMetadataFieldPolicy,
	},
	MerchantTaxonomy?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MerchantTaxonomyKeySpecifier | (() => undefined | MerchantTaxonomyKeySpecifier),
		fields?: MerchantTaxonomyFieldPolicy,
	},
	Mutation?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier),
		fields?: MutationFieldPolicy,
	},
	OverlappingBaseline?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | OverlappingBaselineKeySpecifier | (() => undefined | OverlappingBaselineKeySpecifier),
		fields?: OverlappingBaselineFieldPolicy,
	},
	PageInfo?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | PageInfoKeySpecifier | (() => undefined | PageInfoKeySpecifier),
		fields?: PageInfoFieldPolicy,
	},
	ProfileBenchmark?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProfileBenchmarkKeySpecifier | (() => undefined | ProfileBenchmarkKeySpecifier),
		fields?: ProfileBenchmarkFieldPolicy,
	},
	ProfileEntry?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProfileEntryKeySpecifier | (() => undefined | ProfileEntryKeySpecifier),
		fields?: ProfileEntryFieldPolicy,
	},
	ProviderFinancialAccount?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ProviderFinancialAccountKeySpecifier | (() => undefined | ProviderFinancialAccountKeySpecifier),
		fields?: ProviderFinancialAccountFieldPolicy,
	},
	Query?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier),
		fields?: QueryFieldPolicy,
	},
	RecurringCompanySubscription?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | RecurringCompanySubscriptionKeySpecifier | (() => undefined | RecurringCompanySubscriptionKeySpecifier),
		fields?: RecurringCompanySubscriptionFieldPolicy,
	},
	ReductionTarget?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ReductionTargetKeySpecifier | (() => undefined | ReductionTargetKeySpecifier),
		fields?: ReductionTargetFieldPolicy,
	},
	SIBSEBuildingType?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SIBSEBuildingTypeKeySpecifier | (() => undefined | SIBSEBuildingTypeKeySpecifier),
		fields?: SIBSEBuildingTypeFieldPolicy,
	},
	ScopeCo2eBreakdown?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | ScopeCo2eBreakdownKeySpecifier | (() => undefined | ScopeCo2eBreakdownKeySpecifier),
		fields?: ScopeCo2eBreakdownFieldPolicy,
	},
	SicCode?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SicCodeKeySpecifier | (() => undefined | SicCodeKeySpecifier),
		fields?: SicCodeFieldPolicy,
	},
	SubscriptionAuthenticationParams?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubscriptionAuthenticationParamsKeySpecifier | (() => undefined | SubscriptionAuthenticationParamsKeySpecifier),
		fields?: SubscriptionAuthenticationParamsFieldPolicy,
	},
	SubscriptionCreationResponse?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubscriptionCreationResponseKeySpecifier | (() => undefined | SubscriptionCreationResponseKeySpecifier),
		fields?: SubscriptionCreationResponseFieldPolicy,
	},
	SubscriptionProduct?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubscriptionProductKeySpecifier | (() => undefined | SubscriptionProductKeySpecifier),
		fields?: SubscriptionProductFieldPolicy,
	},
	SubscriptionPromotionalCode?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | SubscriptionPromotionalCodeKeySpecifier | (() => undefined | SubscriptionPromotionalCodeKeySpecifier),
		fields?: SubscriptionPromotionalCodeFieldPolicy,
	},
	TargetingBaseline?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TargetingBaselineKeySpecifier | (() => undefined | TargetingBaselineKeySpecifier),
		fields?: TargetingBaselineFieldPolicy,
	},
	Taxonomy?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaxonomyKeySpecifier | (() => undefined | TaxonomyKeySpecifier),
		fields?: TaxonomyFieldPolicy,
	},
	TaxonomyTerm?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TaxonomyTermKeySpecifier | (() => undefined | TaxonomyTermKeySpecifier),
		fields?: TaxonomyTermFieldPolicy,
	},
	Transaction?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionKeySpecifier | (() => undefined | TransactionKeySpecifier),
		fields?: TransactionFieldPolicy,
	},
	TransactionBase?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionBaseKeySpecifier | (() => undefined | TransactionBaseKeySpecifier),
		fields?: TransactionBaseFieldPolicy,
	},
	TransactionConnection?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionConnectionKeySpecifier | (() => undefined | TransactionConnectionKeySpecifier),
		fields?: TransactionConnectionFieldPolicy,
	},
	TransactionEdge?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | TransactionEdgeKeySpecifier | (() => undefined | TransactionEdgeKeySpecifier),
		fields?: TransactionEdgeFieldPolicy,
	},
	UploadValidationErrors?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UploadValidationErrorsKeySpecifier | (() => undefined | UploadValidationErrorsKeySpecifier),
		fields?: UploadValidationErrorsFieldPolicy,
	},
	User?: Omit<TypePolicy, "fields" | "keyFields"> & {
		keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier),
		fields?: UserFieldPolicy,
	}
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;