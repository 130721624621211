import React from 'react';
import {useQuery, useMutation} from '@apollo/client';
import SplitButton from 'carbon-react/lib/components/split-button';
import CarbonButton from 'carbon-react/lib/components/button';
import Tooltip from 'carbon-react/lib/components/tooltip';
import Loader from 'carbon-react/lib/components/loader';
import Message from 'carbon-react/lib/components/message';

import {Heading2, Button, DetailText, VStack, HStack, Strong} from '@sphericsio/design-system';

import {Loading} from '../loading';
import {ErrorCard} from '../error-card';
import {graphql} from '../../graphql/generated';
import {Table, TBody, TData, THead, THeader, TRow} from '../table';
import {DateDisplay} from '../date-display';
import {useBackgroundExecutionStatus} from '../../hooks/background-execution';
import {
    EmissionsRefreshTarget,
    FinancialDataQuality,
    FinancialProviderSync,
} from '../../graphql/generated/graphql';
import {AlertIcon} from '../icons';

const GetCompanyAccountingDataDocument = graphql(/* GraphQL */ `
    query GetCompanyAccountingData($id: ID!) {
        getCompany(id: $id) {
            id
            financialDataQuality {
                totalTransactions
                totalMerchants
                transactionsWithoutMerchants {
                    id
                    description
                    transaction_date
                    amount
                }
            }
            financialProviderSyncStatus {
                id
                created_at
                completed_at
                sync_type
                sync_method
                sync_status
            }
        }
    }
`);

type FinancialDataQualityReportProps = {
    data: Pick<FinancialDataQuality, 'totalMerchants' | 'totalTransactions'>;
};

const FinancialDataQualityReport: React.FC<FinancialDataQualityReportProps> = ({data}) => {
    return (
        <VStack>
            <Heading2>Accounting data stats</Heading2>
            <HStack>
                <DetailText inline={false}>Total merchants:</DetailText>
                <Strong>{data.totalMerchants}</Strong>
            </HStack>
            <HStack>
                <DetailText inline={false}>Total transactions:</DetailText>
                <Strong>{data.totalTransactions}</Strong>
            </HStack>
        </VStack>
    );
};

const ForceCompleteProviderResyncDocument = graphql(/* GraphQL */ `
    mutation ForceCompleteProviderResync($companyAccountId: ID!, $financialProvider: String!) {
        forceCompleteFinancialProviderResync(
            companyAccountId: $companyAccountId
            financialProvider: $financialProvider
        ) {
            id
        }
    }
`);

function useForceProviderResync() {
    const [execute, {loading, error, data}] = useMutation(ForceCompleteProviderResyncDocument);
    const poll = useBackgroundExecutionStatus();
    const backgroundExecutionId = data?.forceCompleteFinancialProviderResync?.id;
    React.useEffect(() => {
        if (backgroundExecutionId != null && !poll.called) {
            poll.startPolling({id: backgroundExecutionId});
        }
    }, [backgroundExecutionId, poll]);

    if (poll.called) {
        return [
            execute,
            {loading: poll.loading || !poll.finished, error: poll.error, data: poll.data},
        ] as const;
    } else {
        return [execute, {loading, error, data}] as const;
    }
}

type FinancialProviderSyncProps = {
    companyAccountId: string;
    data: FinancialProviderSync[];
};
function FinancialProviderSyncs({companyAccountId, data}: FinancialProviderSyncProps) {
    const [execute, {loading, error}] = useForceProviderResync();
    return (
        <VStack>
            <Heading2>Accounting API Syncs</Heading2>
            <Table>
                <THead>
                    <TRow>
                        <THeader>ID</THeader>
                        <THeader>Provider</THeader>
                        <THeader>Sync Method</THeader>
                        <THeader>Status</THeader>
                        <THeader>Started At</THeader>
                        <THeader>Completed At</THeader>
                        <THeader>Actions</THeader>
                    </TRow>
                </THead>
                <TBody>
                    {data.length === 0 && (
                        <TRow>
                            <TData colSpan={6}>No Syncs.</TData>
                        </TRow>
                    )}
                    {data.map((sync) => (
                        <TRow key={sync.id}>
                            <TData>{sync.id}</TData>
                            <TData>{sync.sync_type}</TData>
                            <TData>{sync.sync_method}</TData>
                            <TData>{sync.sync_status}</TData>
                            <TData>
                                <DateDisplay date={sync.created_at} />
                            </TData>
                            <TData>
                                {sync.completed_at == null ? (
                                    'In progress'
                                ) : (
                                    <DateDisplay date={sync.completed_at} />
                                )}
                            </TData>
                            <TData>
                                {sync.completed_at != null && (
                                    <Tooltip message="Performs a complete resync of all the company's data from their accounting platform. This operation isn't destructive and will preserve all user provided information, such as manual categorisation. This is however slow and puts high load on our servers. If you're not a dev, don't use it :)">
                                        <div>
                                            <Button
                                                icon={<AlertIcon />}
                                                isLoading={loading}
                                                bg="error"
                                                onPress={() => {
                                                    const ok = confirm(
                                                        'Do you really want to perform a complete resync of all of this companys data from their accounting platform?',
                                                    );
                                                    if (ok) {
                                                        execute({
                                                            variables: {
                                                                companyAccountId,
                                                                financialProvider: sync.sync_type,
                                                            },
                                                        });
                                                    }
                                                }}
                                            >
                                                Force complete resync
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )}
                            </TData>
                        </TRow>
                    ))}
                </TBody>
            </Table>
            {error && <ErrorCard />}
        </VStack>
    );
}

const ResetCompanyDataDocument = graphql(/* GraphQL */ `
    mutation ResetCompanyData($id: ID!) {
        resetCompanyData(id: $id) {
            id
        }
    }
`);

const DisconnectCompanyFinancialProviderDocument = graphql(/* GraphQL */ `
    mutation DisconnectCompanyFinancialProvider($companyAccountId: String!) {
        disconnectCompanyFinancialProvider(companyAccountId: $companyAccountId) {
            id
            sync_type
            sync_status
            created_at
        }
    }
`);

const RefreshCompanyTransactionDataDocument = graphql(/* GraphQL */ `
    mutation RefreshCompanyTransactionData($id: ID!, $target: EmissionsRefreshTarget!) {
        refreshCompanyTransactions(id: $id, target: $target) {
            id
        }
    }
`);

type RefreshCompanyTransactionDataButtonProps = {
    companyId: string;
};
const RefreshCompanyTransactionDataButton: React.FC<RefreshCompanyTransactionDataButtonProps> = ({
    companyId,
}) => {
    const [refresh, {loading, error, data, reset}] = useMutation(
        RefreshCompanyTransactionDataDocument,
    );

    function doRefresh(target: EmissionsRefreshTarget) {
        return refresh({variables: {id: companyId, target}});
    }

    const targets = [
        {
            label: 'Recalculate all emissions',
            comment:
                'This will recalculate emissions associated with all data we have for this company. This includes transaction emissions, quarterly operations emissions, and the baseline year emissions.',
            action: () => doRefresh('all'),
        },
        {
            label: 'Recalculate only transactions emissions',
            comment:
                "This will recalculate emissions associated with the transactions we synced previously from the accounting platform. This won't include transactions linked to operations, such as energy spend.",
            action: () => doRefresh('transactions'),
        },
        {
            label: 'Recalculate only operations emissions',
            comment:
                "This will recalculate emissions associated with the company's operations, both for the baseline year and for the quarterly entries. This will also recalcuate emissions for transactions linked to operations, such as energy spend.",
            action: () => doRefresh('operations'),
        },
    ];

    const [target, setTarget] = React.useState(targets[0]);

    const icon = error ? 'warning' : data ? 'tick' : 'refresh';

    return (
        <>
            <div className="flex items-center">
                {loading ? (
                    <LoaderButton />
                ) : (
                    <SplitButton text={target.label} onClick={target.action} iconType={icon}>
                        {targets.map((target, i) => (
                            <Tooltip message={target.comment} position="right" key={i}>
                                <CarbonButton
                                    onClick={() => {
                                        setTarget(target);
                                        reset();
                                    }}
                                >
                                    {target.label}
                                </CarbonButton>
                            </Tooltip>
                        ))}
                    </SplitButton>
                )}
                <div className="ml-2 max-w-lg">
                    <DetailText>{target.comment}</DetailText>
                </div>
            </div>
            {data && (
                <Message variant="success">
                    The operation has been scheduled and emissions will soon be recalculated. This
                    can take up to half an hour to complete.
                </Message>
            )}
            {error && <ErrorCard message={`Something went wrong: ${error.message}`} />}
        </>
    );
};

function LoaderButton() {
    return (
        <div className="w-64">
            <CarbonButton fullWidth>
                <Loader isInsideButton />
            </CarbonButton>
        </div>
    );
}

type ResetCompanyDataButtonProps = {
    companyId: string;
};
function ResetCompanyDataButton({companyId}: ResetCompanyDataButtonProps) {
    const [reset, {loading, error}] = useMutation(ResetCompanyDataDocument);
    React.useEffect(() => {
        if (error != null) {
            alert('Sorry, something went wrong');
        }
    }, [error]);
    return (
        <div className="flex items-start">
            <Button
                icon={<AlertIcon />}
                isLoading={loading}
                bg="error"
                onPress={() => {
                    const ok = confirm(
                        'Do you really want to reset the data for this company? This cannot be reversed!',
                    );
                    if (ok) {
                        reset({variables: {id: companyId}});
                    }
                }}
            >
                Reset company data
            </Button>
        </div>
    );
}

type DisconnectCompanyFinancialProviderButtonProps = {
    companyId: string;
};

function DisconnectCompanyFinancialProviderButton({
    companyId,
}: DisconnectCompanyFinancialProviderButtonProps) {
    const [disconnect, {loading, error}] = useMutation(DisconnectCompanyFinancialProviderDocument);
    React.useEffect(() => {
        if (error != null) {
            alert('Sorry, something went wrong');
        }
    }, [error]);
    return (
        <div className="flex items-start">
            <Button
                icon={<AlertIcon />}
                isLoading={loading}
                bg="error"
                onPress={() => {
                    const ok = confirm(
                        'Do you really want to disconnect the financial provider sync for this company? This cannot be reversed!',
                    );
                    if (ok) {
                        disconnect({variables: {companyAccountId: companyId}});
                    }
                }}
            >
                Disconnect financial provider
            </Button>
        </div>
    );
}

type CompanyAccountingDataProps = {
    companyAccountId: string;
};
export function CompanyAccountingData({companyAccountId}: CompanyAccountingDataProps) {
    const {loading, error, data} = useQuery(GetCompanyAccountingDataDocument, {
        variables: {id: companyAccountId},
    });
    return (
        <VStack>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <>
                    <FinancialDataQualityReport data={data.getCompany.financialDataQuality} />
                    <FinancialProviderSyncs
                        companyAccountId={companyAccountId}
                        data={data.getCompany.financialProviderSyncStatus}
                    />
                    <RefreshCompanyTransactionDataButton companyId={companyAccountId} />
                    <ResetCompanyDataButton companyId={companyAccountId} />
                    <DisconnectCompanyFinancialProviderButton companyId={companyAccountId} />
                </>
            )}
        </VStack>
    );
}
