import React from 'react';

import {GraphQLQueryContext} from '@sphericsio/mvp-ui-common';

import {Loading} from './loading';
import {ErrorCard} from './error-card';
import {FragmentType, graphql, useFragment} from '../graphql/generated';
import {CurrentUserFragmentDocument} from '../graphql/fragments';

export const GetCurrentUserDocument = graphql(/* GraphQL */ `
    query GetCurrentUser {
        currentUser {
            ...CurrentUser
        }
    }
`);
export type User = FragmentType<typeof CurrentUserFragmentDocument>;

const {ctx, QueryDataProvider} = GraphQLQueryContext.buildContext(
    GetCurrentUserDocument,
    <Loading />,
    <ErrorCard />,
    (data) => useFragment(CurrentUserFragmentDocument, data.currentUser),
);

export function useCurrentUser() {
    const data = React.useContext(ctx);
    return data;
}

export const CurrentUserProvider = QueryDataProvider;
