import React from 'react';
import {useMutation, useQuery} from '@apollo/client';
import {Temporal} from 'temporal-polyfill';

import {
    Button,
    ButtonLabel,
    DetailText,
    Heading3,
    LoadingSpinner,
    Modal,
    VStack,
} from '@sphericsio/design-system';
import {getErrorMessage} from '@sphericsio/mvp-ui-common';

import {graphql} from '../../graphql/generated';
import {Loading} from '../loading';
import {ErrorCard} from '../error-card';
import {Table, TBody, TData, THead, THeader, TRow} from '../table';
import {AddUserForm} from './add-user-form';
import {DisableUserForm} from './disable-user-form';
import {SwitchToAccountancyLogin} from './switch-user-to-accountancy-login';

const GetCompanyUsers = graphql(/* GraphQL */ `
    query GetCompanyUsers($companyAccountId: ID!) {
        getCompanyUsers(companyAccountId: $companyAccountId) {
            id
            name
            email
            roles
            enabled
        }
    }
`);

const ResendInvite = graphql(/* GraphQL */ `
    mutation ResendInvite($email: String!, $companyAccountId: ID!) {
        resendCompanyUserInvite(userEmail: $email, companyAccountId: $companyAccountId) {
            code
            expires_at
        }
    }
`);

export function UserManagement({companyAccountId}: {companyAccountId: string}) {
    const {loading, error, data, refetch} = useQuery(GetCompanyUsers, {
        variables: {companyAccountId},
    });
    return (
        <VStack>
            {loading && <Loading />}
            {error && <ErrorCard />}
            {data && (
                <CompanyUserTable
                    users={data.getCompanyUsers}
                    refetch={refetch}
                    companyAccountId={companyAccountId}
                />
            )}
        </VStack>
    );
}

type User = {
    id: string;
    name: string;
    email: string;
    roles: string[];
    enabled: boolean;
};

type CompanyUserTableProps = {
    users: User[];
    refetch: () => unknown;
    companyAccountId: string;
};

function CompanyUserTable({users, refetch, companyAccountId}: CompanyUserTableProps) {
    return (
        <>
            <AddUser onDone={refetch} companyAccountId={companyAccountId} />
            <Table>
                <THead>
                    <TRow>
                        <THeader>Name</THeader>
                        <THeader>Email</THeader>
                        <THeader>Roles</THeader>
                        <THeader>Enabled?</THeader>
                        <THeader></THeader>
                    </TRow>
                </THead>
                <TBody>
                    {users.map((u) => (
                        <CompanyUserRow
                            companyAccountId={companyAccountId}
                            user={u}
                            key={u.id}
                            refetch={refetch}
                        />
                    ))}
                </TBody>
            </Table>
        </>
    );
}

function AddUser({onDone, companyAccountId}: {onDone: () => unknown; companyAccountId: string}) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            <Button onPress={() => setShowModal(true)}>Add new User</Button>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <AddUserForm
                    companyAccountId={companyAccountId}
                    onDone={() => {
                        setShowModal(false);
                        onDone();
                    }}
                />
            </Modal>
        </>
    );
}

function CompanyUserRow({
    companyAccountId,
    user,
    refetch,
}: {
    companyAccountId: string;
    user: User;
    refetch: () => unknown;
}) {
    return (
        <TRow>
            <TData>{user.name}</TData>
            <TData>{user.email}</TData>
            <TData>{user.roles.join(', ')}</TData>
            <TData>
                {user.enabled ? 'Yes' : 'No'}
                <DisableUserButton user={user} onDisabled={refetch} />
            </TData>
            <TData>
                <SwitchUserToAccountancyLoginButton user={user} />
                {' | '}
                <ResendInviteButton companyAccountId={companyAccountId} email={user.email} />
            </TData>
        </TRow>
    );
}

function DisableUserButton({user, onDisabled}: {user: User; onDisabled: () => unknown}) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            {user.enabled && (
                <>
                    {' | '}
                    <button onClick={() => setShowModal(true)}>
                        <ButtonLabel size="medium" colour="error">
                            Disable
                        </ButtonLabel>
                    </button>
                </>
            )}
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <DisableUserForm
                    user={user}
                    onDisabled={() => {
                        setShowModal(false);
                        onDisabled();
                    }}
                />
            </Modal>
        </>
    );
}

function SwitchUserToAccountancyLoginButton({user}: {user: User}) {
    const [showModal, setShowModal] = React.useState(false);
    return (
        <>
            <button onClick={() => setShowModal(true)}>
                <ButtonLabel size="medium" colour="error">
                    Switch to Accountancy Login
                </ButtonLabel>
            </button>
            <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
                <SwitchToAccountancyLogin
                    user={user}
                    onSuccess={() => {
                        setShowModal(false);
                    }}
                />
            </Modal>
        </>
    );
}

function ResendInviteButton({email, companyAccountId}: {email: string; companyAccountId: string}) {
    const [resendInvite, {loading, error, data, called, reset}] = useMutation(ResendInvite, {
        variables: {
            email,
            companyAccountId,
        },
    });
    return (
        <>
            <button onClick={() => !called && resendInvite()}>
                <ButtonLabel size="medium" colour="error">
                    Re-send invite email
                </ButtonLabel>
            </button>
            <Modal isOpen={called} onClose={reset}>
                <VStack align="center">
                    <Heading3>Re-send invite email</Heading3>
                    {loading && (
                        <>
                            <DetailText>Sending...</DetailText>
                            <LoadingSpinner />
                        </>
                    )}
                    {error && (
                        <ErrorCard
                            message={getErrorMessage(error, {
                                extraErrorCodes: {
                                    INVALID_COMPANY_SYNC_STATUS:
                                        'You cannot invite users to companies that have been synced with financial data',
                                },
                            })}
                        />
                    )}
                    {data && (
                        <>
                            <DetailText>Invite link was sent to {email}.</DetailText>
                            <DetailText>
                                The invite expires at{' '}
                                {Temporal.ZonedDateTime.from(
                                    data.resendCompanyUserInvite.expires_at,
                                ).toLocaleString()}
                            </DetailText>
                            <DetailText colour={'secondary'}>
                                Invite code:{' '}
                                <span data-testid="invite-code">
                                    {data.resendCompanyUserInvite.code}
                                </span>
                            </DetailText>
                        </>
                    )}
                </VStack>
            </Modal>
        </>
    );
}
