import React from 'react';
import {ApolloProvider} from '@apollo/client';

import {System} from '@sphericsio/design-system';

import {BugsnagProvider, ErrorBoundary} from './components/bugsnag';
import {Routes} from './routes';
import {client} from './services/graphql';
import {CurrentUserProvider} from './components/user-context';

import './index.css';

const App: React.FC = () => {
    return (
        <ErrorBoundary>
            <ApolloProvider client={client}>
                <CurrentUserProvider>
                    <BugsnagProvider>
                        <System>
                            <Routes />
                        </System>
                    </BugsnagProvider>
                </CurrentUserProvider>
            </ApolloProvider>
        </ErrorBoundary>
    );
};

export {App};
