import React from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';

import {useCurrentUser} from './user-context';
import {storeRedirectPath} from '../services/login-redirect';

const LoggedInRoutes: React.FC<{children: React.ReactNode}> = ({children}) => {
    const user = useCurrentUser();
    React.useLayoutEffect(() => {
        if (user == null) {
            storeRedirectPath();
        }
    }, [user]);

    if (user == null) {
        return <Navigate to="/login" />;
    }

    return (
        <Route path="*">
            <Routes>{children}</Routes>
        </Route>
    );
};

export {LoggedInRoutes};
